import { PipeTransform, Pipe } from "@angular/core";
import {
  Preacher,
  getPreacherDisplayText
} from "src/app/shared/preacher-service/preacher";
import { PreacherService } from "src/app/shared/preacher-service/preacher.service";
import { map } from "rxjs/operators";

@Pipe({ name: "preacherDisplayText", pure: true })
export class PreacherDisplayTextPipe implements PipeTransform {
  transform(
    preacher: Preacher,
    lastNameFirst = true,
    location: boolean = false
  ) {
    return getPreacherDisplayText(preacher, lastNameFirst, location);
  }
}

@Pipe({ name: "preacherDisplayTextAsync", pure: true })
export class PreacherDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly preacherService: PreacherService) {}

  transform(
    preacherId: number,
    lastNameFirst = true,
    location: boolean = false
  ) {
    return this.preacherService.data$.pipe(
      map(preachers => {
        const preacher = preachers.find(value => value.id === preacherId);
        return preacher
          ? getPreacherDisplayText(preacher, lastNameFirst, location)
          : "";
      })
    );
  }
}
