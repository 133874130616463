import { PipeTransform, Pipe } from "@angular/core";
import {
  CarService,
  getCarServiceDisplayText
} from "src/app/shared/car-service-service/car-service";
import { CarServiceService } from "src/app/shared/car-service-service/car-service.service";
import { map } from "rxjs/operators";

@Pipe({ name: "carServiceDisplayText", pure: true })
export class CarServiceDisplayTextPipe implements PipeTransform {
  transform(carService: CarService, lastNameFirst = true) {
    return getCarServiceDisplayText(carService, lastNameFirst);
  }
}

@Pipe({ name: "carServiceDisplayTextAsync", pure: true })
export class CarServiceDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly carServiceService: CarServiceService) {}

  transform(carServiceId: number, lastNameFirst = true) {
    return this.carServiceService.data$.pipe(
      map(carServices => {
        const carService = carServices.find(value => value.id === carServiceId);
        return carService
          ? getCarServiceDisplayText(carService, lastNameFirst)
          : "";
      })
    );
  }
}
