import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  getPublicationDate,
  Publication,
  PublicationJson,
  publicationJsonToPublication,
} from "src/app/shared/publication-service/publication";
import { DataService } from "src/app/shared/data-service";
import { DataReferences } from "src/app/shared/data-references";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { getWeekNumber } from "src/app/shared/publication-service/publication-week-number.pipe";
import { formatDate } from "src/app/shared/util/util";

@Injectable()
export class PublicationService extends DataService<
  PublicationJson,
  Publication,
  undefined
> {
  protected csvColumnValueGetter = [
    {column: "number", title: "Nr", getter: (data: Publication) => data.number.toString()},
    {column: "week", title: "Wk", getter: (data: Publication) => getWeekNumber(data.dueDate).toString()},
    {column: "dueDate", title: "Inleverdatum", getter: (data: Publication) => formatDate(data.dueDate)},
    {column: "publicationDate", title: "Verschijningsdatum", getter: (data: Publication) => formatDate(getPublicationDate(data))},
    {column: "period", title: "Periode", getter: (data: Publication) => data.period.toString()},
    {column: "correction", title: "Correctie", getter: (data: Publication) => data.correction},
    {column: "remark", title: "Opmerkingen", getter: (data: Publication) => data.remark}
  ];
  protected csvFilename = "KerkbladPublicaties";

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string,
    private readonly serviceService: ServiceService
  ) {
    super(
      http,
      authenticationService,
      `${backendUrl}publications`,
      RightModule.Publication
    );
  }

  jsonToData(json: PublicationJson) {
    return publicationJsonToPublication(json);
  }

  dataToJson(data: Publication) {
    return {
      ...data,
      dueDate: data.dueDate.getTime(),
      publicationDate: data.publicationDate
        ? data.publicationDate.getTime()
        : undefined,
      archived: data.archived ? 1 : 0,
    };
  }

  protected handleReferences(references: DataReferences) {
    if (references.services) {
      this.serviceService.updateReferences(references.services);
    }
  }
}
