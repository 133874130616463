import { NgModule, APP_INITIALIZER } from "@angular/core";

import { BrowserModule, Meta } from "@angular/platform-browser";
import { AppRoutingModule, AuthGuards } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app/app.component";
import { LoginComponent } from "src/app/login/login.component";
import { HandleidingComponent } from "src/app/handleiding/handleiding.component";
import { DomineesComponent } from "src/app/dominees/dominees.component";
import { PreacherDetailsComponent } from "src/app/dominees/preacher-details/preacher-details.component";
import { OuderlingenComponent } from "src/app/ouderlingen/ouderlingen.component";
import { ElderDetailsComponent } from "src/app/ouderlingen/elder-details/elder-details.component";
import { OrganistenComponent } from "src/app/organisten/organisten.component";
import { OrganistDetailsComponent } from "src/app/organisten/organist-details/organist-details.component";
import { AutodienstComponent } from "src/app/autodienst/autodienst.component";
import { CarServiceDetailsComponent } from "src/app/autodienst/car-service-details/car-service-details.component";
import { DeurdienstComponent } from "src/app/deurdienst/deurdienst.component";
import { DoorServiceDetailsComponent } from "src/app/deurdienst/door-service-details/door-service-details.component";
import { KoffiedienstComponent } from "src/app/koffiedienst/koffiedienst.component";
import { CoffeeServiceDetailsComponent } from "src/app/koffiedienst/coffee-service-details/coffee-service-details.component";
import { KCComponent } from "src/app/kc/kc.component";
import { PublicationDetailsComponent } from "src/app/kc/publication-details/publication-details.component";
import { KostersComponent } from "src/app/kosters/kosters.component";
import { ClerkDetailsComponent } from "src/app/kosters/clerk-details/clerk-details.component";
import { SchoonmakersComponent } from "src/app/schoonmakers/schoonmakers.component";
import { CleanerDetailsComponent } from "src/app/schoonmakers/cleaner-details/cleaner-details.component";
import { DienstenComponent } from "src/app/diensten/diensten.component";
import { ServiceDetailsComponent } from "src/app/diensten/service-details/service-details.component";
import { DeleteConfirmDialogComponent } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { FilterComponent } from "./shared/filter/filter.component";
import {
  CarServiceDisplayTextPipe,
  CarServiceDisplayTextAsyncPipe
} from "src/app/shared/car-service-service/car-service-display-text.pipe";
import {
  ClerkDisplayTextPipe,
  ClerkDisplayTextAsyncPipe
} from "src/app/shared/clerk-service/clerk-display-text.pipe";
import {
  CleanerDisplayTextPipe,
  CleanerDisplayTextAsyncPipe
} from "src/app/shared/cleaner-service/cleaner-display-text.pipe";
import {
  DoorServiceDisplayTextPipe,
  DoorServiceDisplayTextAsyncPipe
} from "src/app/shared/door-service-service/door-service-display-text.pipe";
import {
  CoffeeServiceDisplayTextPipe,
  CoffeeServiceDisplayTextAsyncPipe
} from "src/app/shared/coffee-service-service/coffee-service-display-text.pipe";
import { AudioMemberDisplayTextAsyncPipe, AudioMemberDisplayTextPipe } from "src/app/shared/audio-member-service/audio-member-display-text.pipe";
import {
  ElderDisplayTextPipe,
  ElderDisplayTextAsyncPipe
} from "src/app/shared/elder-service/elder-display-text.pipe";
import {
  OrganistDisplayTextPipe,
  OrganistDisplayTextAsyncPipe
} from "src/app/shared/organist-service/organist-display-text.pipe";
import {
  PreacherDisplayTextPipe,
  PreacherDisplayTextAsyncPipe
} from "src/app/shared/preacher-service/preacher-display-text.pipe";
import {
  PublicationDisplayTextPipe,
  PublicationDisplayTextAsyncPipe
} from "src/app/shared/publication-service/publication-display-text.pipe";
import { FormatPublicationDatePipe } from "src/app/shared/publication-service/format-publication-date.pipe";
import { PublicationWeekNumberPipe } from "src/app/shared/publication-service/publication-week-number.pipe";
import { FormatDatePipe, IsEqualDatePipe } from "src/app/shared/util/util";
import { CollectionTextPipe } from "src/app/shared/service-service/collection";

import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";

import { BACKEND_URL } from "src/app/shared/backend-url";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { PreacherService } from "src/app/shared/preacher-service/preacher.service";
import { ElderService } from "src/app/shared/elder-service/elder.service";
import { OrganistService } from "src/app/shared/organist-service/organist.service";
import { CarServiceService } from "src/app/shared/car-service-service/car-service.service";
import { DoorServiceService } from "src/app/shared/door-service-service/door-service.service";
import { CoffeeServiceService } from "src/app/shared/coffee-service-service/coffee-service.service";
import { ClerkService } from "src/app/shared/clerk-service/clerk.service";
import { CleanerService } from "src/app/shared/cleaner-service/cleaner.service";
import { PublicationService } from "src/app/shared/publication-service/publication.service";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { ArchivedPipe } from "src/app/diensten/service-details/archived.pipe";
import { StartupService } from "src/app/shared/startup-service/startup.service";
import { FilterService } from "./shared/filter/filter.service";
import { AudioMemberService } from "src/app/shared/audio-member-service/audio-member.service";
import { AudioMemberDetailsComponent } from "src/app/audio-teams/audio-member-details/audio-member-details.component";
import { AudioTeamsComponent } from "src/app/audio-teams/audio-teams.component";
import { BabysitterDisplayTextAsyncPipe, BabysitterDisplayTextPipe } from "./shared/babysitter-service/babysitter-display-text.pipe";
import { BabysitterService } from "./shared/babysitter-service/babysitter.service";
import { OppasComponent } from "./oppas/oppas.component";
import { BabysitterDetailsComponent } from "./oppas/babysitter-details/babysitter-details.component";
import { NewServiceDialogComponent } from "./shared/new-service-dialog/new-service-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HandleidingComponent,
    DomineesComponent,
    PreacherDetailsComponent,
    OuderlingenComponent,
    ElderDetailsComponent,
    OrganistenComponent,
    OrganistDetailsComponent,
    AutodienstComponent,
    CarServiceDetailsComponent,
    DeurdienstComponent,
    DoorServiceDetailsComponent,
    KoffiedienstComponent,
    CoffeeServiceDetailsComponent,
    AudioTeamsComponent,
    AudioMemberDetailsComponent,
    KCComponent,
    PublicationDetailsComponent,
    KostersComponent,
    ClerkDetailsComponent,
    SchoonmakersComponent,
    CleanerDetailsComponent,
    OppasComponent,
    BabysitterDetailsComponent,
    DienstenComponent,
    ServiceDetailsComponent,
    ArchivedPipe,
    DeleteConfirmDialogComponent,
    NewServiceDialogComponent,
    FilterComponent,
    CarServiceDisplayTextPipe,
    CarServiceDisplayTextAsyncPipe,
    ClerkDisplayTextPipe,
    ClerkDisplayTextAsyncPipe,
    CleanerDisplayTextPipe,
    CleanerDisplayTextAsyncPipe,
    BabysitterDisplayTextPipe,
    BabysitterDisplayTextAsyncPipe,
    DoorServiceDisplayTextPipe,
    DoorServiceDisplayTextAsyncPipe,
    CoffeeServiceDisplayTextPipe,
    CoffeeServiceDisplayTextAsyncPipe,
    AudioMemberDisplayTextPipe,
    AudioMemberDisplayTextAsyncPipe,
    ElderDisplayTextPipe,
    ElderDisplayTextAsyncPipe,
    OrganistDisplayTextPipe,
    OrganistDisplayTextAsyncPipe,
    PreacherDisplayTextPipe,
    PreacherDisplayTextAsyncPipe,
    PublicationDisplayTextPipe,
    PublicationDisplayTextAsyncPipe,
    FormatPublicationDatePipe,
    PublicationWeekNumberPipe,
    FormatDatePipe,
    CollectionTextPipe,
    IsEqualDatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatDividerModule,
    MatSortModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: BACKEND_URL,
      useFactory: backendUrlFactory,
      deps: [Meta]
    },
    AuthenticationService,
    PreacherService,
    ElderService,
    OrganistService,
    CarServiceService,
    DoorServiceService,
    CoffeeServiceService,
    AudioMemberService,
    ClerkService,
    CleanerService,
    BabysitterService,
    PublicationService,
    ServiceService,
    StartupService,
    FilterService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    ...AuthGuards
  ],
  entryComponents: [DeleteConfirmDialogComponent, NewServiceDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function backendUrlFactory(meta: Meta) {
  const backendUrlMeta = meta.getTag("itemprop='backend-url'");
  return (backendUrlMeta && backendUrlMeta.content) || "";
}

export function startupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}
