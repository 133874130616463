import { Data, JsonData } from "src/app/shared/data";

export interface PublicationJson extends JsonData {
  remark: string;
  number: number;
  dueDate: number;
  publicationDate?: number;
  period: number;
  correction: string;
}

export interface Publication extends Data {
  remark: string;
  number: number;
  dueDate: Date;
  publicationDate?: Date;
  period: number;
  correction: string;
}

export function publicationJsonToPublication(
  json: PublicationJson
): Publication {
  return {
    ...json,
    dueDate: new Date(json.dueDate),
    publicationDate:
      typeof json.publicationDate === "number"
        ? new Date(json.publicationDate)
        : undefined,
    archived: json.archived === 1,
  };
}

export function getPublicationDisplayText(
  publication: Publication,
  year: boolean = false
) {
  let yearDisplayText = "";
  if (year) {
    const publicationDate = getPublicationDate(publication);
    yearDisplayText = ` ${publicationDate.getFullYear()}`;
  }
  return `${publication.number}${yearDisplayText}`;
}

export function getPublicationDate(publication: Publication) {
  if (publication.publicationDate instanceof Date) {
    return publication.publicationDate;
  }
  const publicationDate = new Date(publication.dueDate);
  const saturday = 6;
  publicationDate.setDate(
    publicationDate.getDate() + saturday - publicationDate.getDay()
  );
  return publicationDate;
}
