import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { Router } from "@angular/router";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "boezemkerk-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
  loginForm = new FormGroup({
    userMail: new FormControl("", [Validators.email, Validators.required]),
    password: new FormControl("", [Validators.required])
  });

  get userMailControl() {
    return this.loginForm.controls.userMail;
  }

  get passwordControl() {
    return this.loginForm.controls.password;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  getUserMailErrorMessage() {
    if (this.userMailControl.hasError("required")) {
      return "Vul een e-mailadres in";
    } else if (this.userMailControl.hasError("email")) {
      return "Vul een geldige e-mailadres in";
    }
    return "";
  }

  getPasswordErrorMessage() {
    if (this.passwordControl.hasError("required")) {
      return "Vul een wachtwoord in";
    }
    return "";
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.userMailControl.disable();
      this.passwordControl.disable();
      this.authenticationService
        .login(this.loginForm.value)
        .pipe(
          switchMap(result =>
            this.authenticationService.getRightLevel(RightModule.Service)
          )
        )
        .subscribe(
          rightLevel => {
            if (rightLevel > RightLevel.None) {
              this.router.navigate(["/diensten"]);
            } else {
              this.router.navigate(["/handleiding"]);
            }
            this.userMailControl.enable();
            this.passwordControl.enable();
            this.loginForm.patchValue({
              userMail: "",
              password: ""
            });
          },
          error => {
            this.userMailControl.enable();
            this.passwordControl.enable();
            this.loginForm.patchValue({
              userMail: this.userMailControl.value,
              password: ""
            });
          }
        );
    }
  }
}
