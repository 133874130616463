import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  AudioMember,
  AudioMemberJson,
  audioMemberJsonToAudioMember,
  getAudioMemberDisplayText
} from "src/app/shared/audio-member-service/audio-member";
import { DataService } from "src/app/shared/data-service";
import { DataReferences } from "src/app/shared/data-references";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { RightModule } from "src/app/shared/authentication-service/right-module";

@Injectable()
export class AudioMemberService extends DataService<AudioMemberJson, AudioMember, undefined> {
  protected csvColumnValueGetter = [
    {column: "name", title: "Naam", getter: (data: AudioMember) => getAudioMemberDisplayText(data)},
    {column: "phone", title: "Telefoon", getter: (data: AudioMember) => data.phone},
    {column: "email", title: "Email", getter: (data: AudioMember) => data.email},
    {column: "remark", title: "Opmerkingen", getter: (data: AudioMember) => data.remark}
  ];
  protected csvFilename = "AudioTeam";

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string,
    private readonly serviceService: ServiceService
  ) {
    super(
      http,
      authenticationService,
      `${backendUrl}audiomembers`,
      RightModule.AudioMember
    );
  }

  jsonToData(json: AudioMemberJson) {
    return audioMemberJsonToAudioMember(json);
  }

  dataToJson(data: AudioMember) {
    return {
      ...data,
      archived: data.archived ? 1 : 0
    };
  }

  protected handleReferences(references: DataReferences) {
    if (references.services) {
      this.serviceService.updateReferences(references.services);
    }
  }
}
