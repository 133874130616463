import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface FilterData {
  from: Date | null;
  to: Date | null;
}

function getMonthInPastInclusive() {
  const date = new Date();
  date.setDate(date.getDate() - 14);
  date.setHours(0, 0, 0, 0);
  return date;
}

function get2MonthsInFutureInclusive() {
  const date = new Date();
  date.setMonth(date.getMonth() + 3);
  date.setDate(0);
  date.setHours(0, 0, 0, 0);
  return date;
}

@Injectable()
export class FilterService {
  private readonly _data = new BehaviorSubject<FilterData>({
    from: getMonthInPastInclusive(),
    to: get2MonthsInFutureInclusive()
  });

  get data$() {
    return this._data.asObservable();
  }

  get data() {
    return this._data.value;
  }
  set data(value) {
    this._data.next(value);
  }
}
