import { Component, Input } from "@angular/core";
import { CleanerService } from "src/app/shared/cleaner-service/cleaner.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Cleaner } from "src/app/shared/cleaner-service/cleaner";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-cleaner-details",
  templateUrl: "./cleaner-details.component.html",
  styleUrls: ["./cleaner-details.component.scss"]
})
export class CleanerDetailsComponent {
  form = this.createForm(undefined);

  readonly cleaner$ = combineLatest(
    this.cleanerService.data$,
    this.route.paramMap
  ).pipe(
    map(([cleaners, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return cleaners.find(cleaner => cleaner.id === id);
    }),
    tap(cleaner => {
      this.form = this.createForm(cleaner);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cleanerService: CleanerService,
    private readonly fb: FormBuilder
  ) {}

  createForm(cleaner?: Cleaner) {
    return this.fb.group({
      firstName: [cleaner ? cleaner.firstName : ""],
      middleName: [cleaner ? cleaner.middleName : ""],
      lastName: [cleaner ? cleaner.lastName : ""],
      remark: [cleaner ? cleaner.remark : ""]
    });
  }

  onSubmit(cleaner: Cleaner) {
    this.cleanerService.save({ ...cleaner, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
