import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { Observable } from "rxjs";
import { StartupService } from "src/app/shared/startup-service/startup.service";

const editRegExp = /.*\/\d+$/;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  readonly title$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => {
      if (!(event instanceof NavigationEnd)) {
        throw new Error("Expect only NavigationEnd events");
      }
      let title = "";
      if (event.urlAfterRedirects.startsWith("/login")) {
        title = "Inloggen";
      } else if (event.urlAfterRedirects.startsWith("/handleiding")) {
        title = "Handleiding";
      } else if (event.urlAfterRedirects.startsWith("/diensten/audioteam")) {
        title = "Diensten > Audio team";
      } else if (event.urlAfterRedirects.startsWith("/diensten/autodienst")) {
        title = "Diensten > Autodienst";
      } else if (event.urlAfterRedirects.startsWith("/diensten/collecten")) {
        title = "Diensten > Collecten";
      } else if (event.urlAfterRedirects.startsWith("/diensten/deurdienst")) {
        title = "Diensten > Deurdienst";
      } else if (event.urlAfterRedirects.startsWith("/diensten/dominees")) {
        title = "Diensten > Dominees";
      } else if (event.urlAfterRedirects.startsWith("/diensten/kc")) {
        title = "Diensten > Kerkblad publicaties";
      } else if (event.urlAfterRedirects.startsWith("/diensten/koffiedienst")) {
        title = "Diensten > Koffiedienst";
      } else if (event.urlAfterRedirects.startsWith("/diensten/kopij")) {
        title = "Diensten > Kopij";
      } else if (event.urlAfterRedirects.startsWith("/diensten/kosters")) {
        title = "Diensten > Kosters";
      } else if (event.urlAfterRedirects.startsWith("/diensten/organisten")) {
        title = "Diensten > Organisten";
      } else if (event.urlAfterRedirects.startsWith("/diensten/ouderlingen")) {
        title = "Diensten > Ouderlingen";
      } else if (event.urlAfterRedirects.startsWith("/diensten/schoonmakers")) {
        title = "Diensten > Schoonmakers";
      } else if (event.urlAfterRedirects.startsWith("/diensten/oppas")) {
        title = "Diensten > Oppas";
      } else if (event.urlAfterRedirects.startsWith("/diensten/tijden")) {
        title = "Diensten > Tijden";
      } else if (event.urlAfterRedirects.startsWith("/diensten")) {
        title = "Diensten";
      } else if (event.urlAfterRedirects.startsWith("/audioteam")) {
        title = "Audio team";
      } else if (event.urlAfterRedirects.startsWith("/autodienst")) {
        title = "Autodienst";
      } else if (event.urlAfterRedirects.startsWith("/deurdienst")) {
        title = "Deurdienst";
      } else if (event.urlAfterRedirects.startsWith("/dominees")) {
        title = "Dominees";
      } else if (event.urlAfterRedirects.startsWith("/kc")) {
        title = "Kerkblad publicaties";
      } else if (event.urlAfterRedirects.startsWith("/koffiedienst")) {
        title = "Koffiedienst";
      } else if (event.urlAfterRedirects.startsWith("/kosters")) {
        title = "Kosters";
      } else if (event.urlAfterRedirects.startsWith("/organisten")) {
        title = "Organisten";
      } else if (event.urlAfterRedirects.startsWith("/ouderlingen")) {
        title = "Ouderlingen";
      } else if (event.urlAfterRedirects.startsWith("/schoonmakers")) {
        title = "Schoonmakers";
      } else if (event.urlAfterRedirects.startsWith("/oppas")) {
        title = "Oppas";
      }

      if (editRegExp.test(event.urlAfterRedirects)) {
        title += " > Details";
      }

      return title;
    })
  );

  readonly isLoggedIn$: Observable<boolean>;

  readonly hasServiceRights: Observable<boolean>;
  readonly hasCollectionRights: Observable<boolean>;
  readonly hasCopyRights: Observable<boolean>;
  readonly hasPreacherRights: Observable<boolean>;
  readonly hasElderRights: Observable<boolean>;
  readonly hasOrganistRights: Observable<boolean>;
  readonly hasCarServiceRights: Observable<boolean>;
  readonly hasDoorServiceRights: Observable<boolean>;
  readonly hasCoffeeServiceRights: Observable<boolean>;
  readonly hasAudioMemberRights: Observable<boolean>;
  readonly hasPublicationRights: Observable<boolean>;
  readonly hasClerkRights: Observable<boolean>;
  readonly hasCleanerRights: Observable<boolean>;
  readonly hasBabysitterRights: Observable<boolean>;

  readonly title: string;

  constructor(
    private readonly router: Router,
    authentication: AuthenticationService,
    startup: StartupService
  ) {
    this.isLoggedIn$ = authentication.isLoggedIn$;

    this.hasServiceRights = authentication
      .getRightLevel(RightModule.Service)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasCollectionRights = authentication
      .getRightLevel(RightModule.Collection)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasCopyRights = authentication
      .getRightLevel(RightModule.Copy)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasPreacherRights = authentication
      .getRightLevel(RightModule.Preacher)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasElderRights = authentication
      .getRightLevel(RightModule.Elder)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasOrganistRights = authentication
      .getRightLevel(RightModule.Organist)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasCarServiceRights = authentication
      .getRightLevel(RightModule.CarService)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasDoorServiceRights = authentication
      .getRightLevel(RightModule.DoorService)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasCoffeeServiceRights = authentication
      .getRightLevel(RightModule.CoffeeService)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasAudioMemberRights = authentication
      .getRightLevel(RightModule.AudioMember)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasPublicationRights = authentication
      .getRightLevel(RightModule.Publication)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasClerkRights = authentication
      .getRightLevel(RightModule.Clerk)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasCleanerRights = authentication
      .getRightLevel(RightModule.Cleaner)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));
    this.hasBabysitterRights = authentication
      .getRightLevel(RightModule.Babysitter)
      .pipe(map((rightLevel) => rightLevel > RightLevel.None));

    this.title = startup.title;
  }
}
