import { PipeTransform, Pipe } from "@angular/core";
import {
  DoorService,
  getDoorServiceDisplayText
} from "src/app/shared/door-service-service/door-service";
import { DoorServiceService } from "src/app/shared/door-service-service/door-service.service";
import { map } from "rxjs/operators";

@Pipe({ name: "doorServiceDisplayText", pure: true })
export class DoorServiceDisplayTextPipe implements PipeTransform {
  transform(doorService: DoorService, lastNameFirst = true) {
    return getDoorServiceDisplayText(doorService, lastNameFirst);
  }
}

@Pipe({ name: "doorServiceDisplayTextAsync", pure: true })
export class DoorServiceDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly doorServiceService: DoorServiceService) {}

  transform(doorServiceId: number, lastNameFirst = true) {
    return this.doorServiceService.data$.pipe(
      map(doorServices => {
        const doorService = doorServices.find(
          value => value.id === doorServiceId
        );
        return doorService
          ? getDoorServiceDisplayText(doorService, lastNameFirst)
          : "";
      })
    );
  }
}
