import { Data, JsonData } from "src/app/shared/data";

export interface OrganistJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface Organist extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

export function organistJsonToOrganist(json: OrganistJson): Organist {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getOrganistDisplayText(
  organist: Organist,
  lastNameFirst = true
) {
  const result = `${organist.firstName} ${organist.middleName}`;
  if (lastNameFirst) {
    return `${organist.lastName}, ${result}`;
  }
  return `${result} ${organist.lastName}`;
}
