import { PipeTransform, Pipe } from "@angular/core";
import {
  Cleaner,
  getCleanerDisplayText
} from "src/app/shared/cleaner-service/cleaner";
import { CleanerService } from "src/app/shared/cleaner-service/cleaner.service";
import { map } from "rxjs/operators";

@Pipe({ name: "cleanerDisplayText", pure: true })
export class CleanerDisplayTextPipe implements PipeTransform {
  transform(cleaner: Cleaner, lastNameFirst = true) {
    return getCleanerDisplayText(cleaner, lastNameFirst);
  }
}

@Pipe({ name: "cleanerDisplayTextAsync", pure: true })
export class CleanerDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly cleanerService: CleanerService) {}

  transform(cleanerId: number, lastNameFirst = true) {
    return this.cleanerService.data$.pipe(
      map(cleaners => {
        const cleaner = cleaners.find(value => value.id === cleanerId);
        return cleaner ? getCleanerDisplayText(cleaner, lastNameFirst) : "";
      })
    );
  }
}
