import { Data, JsonData } from "src/app/shared/data";

export interface ElderJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Elder extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export function elderJsonToElder(json: ElderJson): Elder {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getElderDisplayText(
  elder: Elder,
  lastNameFirst = true,
  detail = ""
) {
  const result = `${elder.firstName} ${elder.middleName}`;
  if (detail.length > 0) {
    detail = `, ${detail}`;
  }
  if (lastNameFirst) {
    return `${elder.lastName}, ${result}${detail}`;
  }
  return `${elder.firstName} ${elder.middleName} ${elder.lastName}${detail}`;
}
