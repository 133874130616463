import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PublicationService } from "src/app/shared/publication-service/publication.service";
import {
  Publication,
  getPublicationDisplayText,
  getPublicationDate,
} from "src/app/shared/publication-service/publication";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { DeleteConfirmDialogComponent } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { DeleteConfirmData } from "src/app/shared/delete-confirm-dialog/delete-confirm-data";
import { Source } from "src/app/shared/util/source";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { tableWidthCalculator } from "src/app/shared/util/util";
import { combineLatest, Subject } from "rxjs";
import { FilterService } from "src/app/shared/filter/filter.service";

@Component({
  selector: "boezemkerk-kc",
  templateUrl: "./kc.component.html",
  styleUrls: ["./kc.component.scss"],
})
export class KCComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly dataSource = new Source(
    combineLatest([
      this.publicationService.data$,
      this.filterService.data$,
    ]).pipe(
      map(([publications, filterData]) => {
        return publications.filter((publication) => {
          const publicationDate = getPublicationDate(publication);
          if (filterData.from && publicationDate < filterData.from) {
            return false;
          }
          if (filterData.to) {
            const nextDay = new Date(filterData.to);
            nextDay.setDate(nextDay.getDate() + 1);
            if (publicationDate >= nextDay) {
              return false;
            }
          }
          return true;
        });
      }),
      tap((publications) => {
        const selected = [...this.selectedPublications];
        this.selectedPublications.splice(0, this.selectedPublications.length);
        this.selectedPublications.push(
          ...selected.filter((selectedId) =>
            publications.find((service) => service.id === selectedId)
          )
        );
      })
    ),
    DataSourceSort,
    { active: "number", direction: "asc" }
  );

  readonly selectedPublications: number[] = [];

  readonly RightLevel = RightLevel;
  readonly rightLevel = this.authenticationService.getRightLevel(
    RightModule.Publication
  );

  private readonly displayColumns$ = this.rightLevel.pipe(
    map((rightLevel) => {
      const displayColumns = [
        "filter",
        "number",
        "week",
        "dueDate",
        "publicationDate",
        "period",
        "correction",
        "remark",
        "download",
      ];
      if (rightLevel >= RightLevel.Adjust) {
        displayColumns.push("actionAdjust");
      }
      if (rightLevel >= RightLevel.Archive) {
        displayColumns.push("actionArchive");
      }
      if (rightLevel >= RightLevel.Delete) {
        displayColumns.push("actionDelete");
      }
      return displayColumns;
    })
  );
  displayColumns: string[] = [];
  readonly tableWidth$ = this.displayColumns$.pipe(
    map(
      tableWidthCalculator({
        filter: 40,
        number: 80,
        week: 20,
        dueDate: 75,
        publicationDate: 110,
        period: 45,
        correction: 60,
        remark: 140,
        download: 40,
        actionAdjust: 40,
        actionArchive: 40,
        actionDelete: 40,
      })
    )
  );

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly publicationService: PublicationService,
    private readonly dialog: MatDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly filterService: FilterService
  ) {
    this.displayColumns$
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayColumns) => (this.displayColumns = displayColumns));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.dataSource.setSort(this.sort.sortChange);
  }

  download() {
    combineLatest([this.dataSource.data$, this.displayColumns$])
      .pipe(take(1))
      .subscribe(([data, displayColumns]) => {
        this.publicationService.downloadCsv(data, undefined, displayColumns);
      });
  }

  addPublication() {
    this.publicationService.save({
      id: 0,
      number: 0,
      dueDate: new Date(),
      publicationDate: undefined,
      period: 0,
      correction: "",
      remark: "",
      archived: false,
    });
  }

  archivePublication(publication: Publication) {
    this.publicationService.archive({
      ...publication,
      archived: !publication.archived,
    });
  }

  removePublication(publication: Publication) {
    this.publicationService.delete(publication, (deleteInfo, confirm) => {
      this.dialog
        .open<DeleteConfirmDialogComponent, DeleteConfirmData, boolean>(
          DeleteConfirmDialogComponent,
          {
            data: {
              title: `Kerkblad publicatie: ${getPublicationDisplayText(
                publication
              )}`,
              objectName: "kerkblad publicatie",
              deleteInfo,
            },
          }
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            confirm();
          }
        });
    });
  }

  rowSelect(publication: Publication) {
    const index = this.selectedPublications.indexOf(publication.id);

    if (this.selectedPublications.length <= 1) {
      this.selectedPublications.splice(0, this.selectedPublications.length);
      if (index !== 0) {
        this.selectedPublications.push(publication.id);
      }
      return;
    }

    if (index >= 0) {
      this.selectedPublications.splice(index, 1);
    } else {
      this.selectedPublications.push(publication.id);
    }
  }

  rowMultiSelect(publication: Publication, event: MouseEvent) {
    event.preventDefault();
    if (this.selectedPublications.indexOf(publication.id) === -1) {
      this.selectedPublications.push(publication.id);
    }
  }
}

function DataSourceSort(a: Publication, b: Publication, sort: Sort) {
  let value = 0;
  switch (sort.active) {
    case "number":
      value = a.number - b.number;
      break;
    case "week":
    case "dueDate":
      value = a.dueDate.getTime() - b.dueDate.getTime();
      break;
    case "publicationDate":
      value = getPublicationDate(a).getTime() - getPublicationDate(b).getTime();
      break;
    case "period":
      value = a.period - b.period;
      break;
    case "correction":
      value = a.correction.localeCompare(b.correction);
      break;
    case "remark":
      value = a.remark.localeCompare(b.remark);
      break;
    default:
      value = a.id - b.id;
  }
  return value * (sort.direction === "desc" ? -1 : 1);
}
