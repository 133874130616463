import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DoorServiceService } from "src/app/shared/door-service-service/door-service.service";
import {
  DoorService,
  getDoorServiceDisplayText,
} from "src/app/shared/door-service-service/door-service";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { DeleteConfirmDialogComponent } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { DeleteConfirmData } from "src/app/shared/delete-confirm-dialog/delete-confirm-data";
import { Source } from "src/app/shared/util/source";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { tableWidthCalculator } from "src/app/shared/util/util";
import { combineLatest, Subject } from "rxjs";

@Component({
  selector: "boezemkerk-deurdienst",
  templateUrl: "./deurdienst.component.html",
  styleUrls: ["./deurdienst.component.scss"],
})
export class DeurdienstComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly dataSource = new Source(
    this.doorServiceService.data$.pipe(
      tap((doorServices) => {
        const selected = [...this.selectedDoorServices];
        this.selectedDoorServices.splice(0, this.selectedDoorServices.length);
        this.selectedDoorServices.push(
          ...selected.filter((selectedId) =>
          doorServices.find((service) => service.id === selectedId)
          )
        );
      })
    ),
    DataSourceSort,
    { active: "name", direction: "asc" }
  );

  readonly selectedDoorServices: number[] = [];

  readonly RightLevel = RightLevel;
  readonly rightLevel = this.authenticationService.getRightLevel(
    RightModule.DoorService
  );

  private readonly displayColumns$ = this.rightLevel.pipe(
    map((rightLevel) => {
      const displayColumns = ["name", "remark", "download"];
      if (rightLevel >= RightLevel.Adjust) {
        displayColumns.push("actionAdjust");
      }
      if (rightLevel >= RightLevel.Archive) {
        displayColumns.push("actionArchive");
      }
      if (rightLevel >= RightLevel.Delete) {
        displayColumns.push("actionDelete");
      }
      return displayColumns;
    })
  );
  displayColumns: string[] = [];
  readonly tableWidth$ = this.displayColumns$.pipe(
    map(
      tableWidthCalculator({
        name: 140,
        remark: 140,
        download: 40,
        actionAdjust: 40,
        actionArchive: 40,
        actionDelete: 40,
      })
    )
  );

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly doorServiceService: DoorServiceService,
    private readonly dialog: MatDialog,
    private readonly authenticationService: AuthenticationService
  ) {
    this.displayColumns$
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayColumns) => (this.displayColumns = displayColumns));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.dataSource.setSort(this.sort.sortChange);
  }

  download() {
    combineLatest(this.dataSource.data$, this.displayColumns$)
      .pipe(take(1))
      .subscribe(([data, displayColumns]) => {
        this.doorServiceService.downloadCsv(data, undefined, displayColumns);
      });
  }

  addDoorService() {
    this.doorServiceService.save({
      id: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      remark: "",
      archived: false,
    });
  }

  archiveDoorService(doorService: DoorService) {
    this.doorServiceService.archive({
      ...doorService,
      archived: !doorService.archived,
    });
  }

  removeDoorService(doorService: DoorService) {
    this.doorServiceService.delete(doorService, (deleteInfo, confirm) => {
      this.dialog
        .open<DeleteConfirmDialogComponent, DeleteConfirmData, boolean>(
          DeleteConfirmDialogComponent,
          {
            data: {
              title: `Deurdienst: ${getDoorServiceDisplayText(
                doorService,
                false
              )}`,
              objectName: "deurdienst",
              deleteInfo,
            },
          }
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            confirm();
          }
        });
    });
  }

  rowSelect(doorService: DoorService) {
    const index = this.selectedDoorServices.indexOf(doorService.id);

    if (this.selectedDoorServices.length <= 1) {
      this.selectedDoorServices.splice(0, this.selectedDoorServices.length);
      if (index !== 0) {
        this.selectedDoorServices.push(doorService.id);
      }
      return;
    }

    if (index >= 0) {
      this.selectedDoorServices.splice(index, 1);
    } else {
      this.selectedDoorServices.push(doorService.id);
    }
  }

  rowMultiSelect(doorService: DoorService, event: MouseEvent) {
    event.preventDefault();
    if (this.selectedDoorServices.indexOf(doorService.id) === -1) {
      this.selectedDoorServices.push(doorService.id);
    }
  }
}

function DataSourceSort(a: DoorService, b: DoorService, sort: Sort) {
  let value = 0;
  switch (sort.active) {
    case "name":
      value = `${a.lastName} ${a.firstName} ${a.middleName}`.localeCompare(
        `${b.lastName} ${b.firstName} ${b.middleName}`
      );
      break;
    case "remark":
      value = a.remark.localeCompare(b.remark);
      break;
    default:
      value = a.id - b.id;
  }
  return value * (sort.direction === "desc" ? -1 : 1);
}
