import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  Service,
  ServiceJson,
  serviceJsonToService,
} from "src/app/shared/service-service/service";
import { DataService } from "src/app/shared/data-service";
import { DataReferences } from "src/app/shared/data-references";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { formatDate } from "src/app/shared/util/util";
import { collectionText } from "src/app/shared/service-service/collection";
import {
  CarService,
  getCarServiceDisplayText,
} from "src/app/shared/car-service-service/car-service";
import {
  DoorService,
  getDoorServiceDisplayText,
} from "src/app/shared/door-service-service/door-service";
import {
  CoffeeService,
  getCoffeeServiceDisplayText,
} from "src/app/shared/coffee-service-service/coffee-service";
import {
  AudioMember,
  getAudioMemberDisplayText,
} from "src/app/shared/audio-member-service/audio-member";
import {
  getPreacherDisplayText,
  Preacher,
} from "src/app/shared/preacher-service/preacher";
import {
  getPublicationDisplayText,
  Publication,
} from "src/app/shared/publication-service/publication";
import { Clerk, getClerkDisplayText } from "src/app/shared/clerk-service/clerk";
import {
  Cleaner,
  getCleanerDisplayText,
} from "src/app/shared/cleaner-service/cleaner";
import {
  getOrganistDisplayText,
  Organist,
} from "src/app/shared/organist-service/organist";
import { Elder, getElderDisplayText } from "src/app/shared/elder-service/elder";
import {
  Babysitter,
  getBabysitterDisplayText,
} from "../babysitter-service/babysitter";

interface CsvRef {
  carServices: CarService[];
  doorServices: DoorService[];
  coffeeServices: CoffeeService[];
  audioMembers: AudioMember[];
  preachers: Preacher[];
  publications: Publication[];
  clerks: Clerk[];
  cleaners: Cleaner[];
  babysitters: Babysitter[];
  organists: Organist[];
  elders: Elder[];
}

function getDisplayTextOrId<T extends { id: number }>(
  id: number | undefined,
  data: T[],
  getName: (object: T, lastNameFirst: boolean) => string
) {
  if (id > 0) {
    const object = data.find((item) => item.id === id);
    return object ? getName(object, false) : `<${id}>`;
  }
  return "";
}

function getDoubleDisplayTextOrId<T extends { id: number }>(
  id1: number | undefined,
  id2: number | undefined,
  data: T[],
  getName: (object: T, lastNameFirst: boolean) => string
) {
  if (id1 > 0 && id2 > 0) {
    return `${getDisplayTextOrId(id1, data, getName)} en ${getDisplayTextOrId(
      id2,
      data,
      getName
    )}`;
  } else if (id1 > 0) {
    return getDisplayTextOrId(id1, data, getName);
  }
  return getDisplayTextOrId(id2, data, getName);
}

function getTrippleDisplayTextOrId<T extends { id: number }>(
  id1: number | undefined,
  id2: number | undefined,
  id3: number | undefined,
  data: T[],
  getName: (object: T, lastNameFirst: boolean) => string
) {
  const displayTexts: string[] = [];
  if (id1 > 0) {
    displayTexts.push(getDisplayTextOrId(id1, data, getName));
  }
  if (id2 > 0) {
    displayTexts.push(getDisplayTextOrId(id2, data, getName));
  }
  if (id3 > 0) {
    displayTexts.push(getDisplayTextOrId(id3, data, getName));
  }
  if (displayTexts.length === 3) {
    return `${displayTexts[0]}, ${displayTexts[1]} en ${displayTexts[2]}`;
  } else if (displayTexts.length === 2) {
    return `${displayTexts[0]} en ${displayTexts[1]}`;
  } else if (displayTexts.length === 1) {
    return displayTexts[0];
  }
  return "";
}

@Injectable()
export class ServiceService extends DataService<ServiceJson, Service, CsvRef> {
  protected csvColumnValueGetter = [
    {
      column: "dateTime",
      title: "Datum",
      getter: (data: Service) =>
        formatDate(data.date) + (data.time !== "00:00" ? " " + data.time : ""),
    },
    {
      column: "remark",
      title: "Opmerkingen",
      getter: (data: Service) => data.remark,
    },
    {
      column: "preachersId",
      title: "Predikant",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(
          data.preachersId,
          ref.preachers,
          getPreacherDisplayText
        ),
    },
    { column: "song", title: "Voorzang", getter: (data: Service) => data.song },
    {
      column: "specialGuest",
      title: "Speciale gasten",
      getter: (data: Service) => data.specialGuest,
    },
    {
      column: "eldersId",
      title: "Ouderling",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(data.eldersId, ref.elders, getElderDisplayText),
    },
    {
      column: "organistsId",
      title: "Organist",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(
          data.organistsId,
          ref.organists,
          getOrganistDisplayText
        ),
    },
    {
      column: "carServicesId",
      title: "Autodienst",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(
          data.carServicesId,
          ref.carServices,
          getCarServiceDisplayText
        ),
    },
    {
      column: "doorServicesId",
      title: "Deurdienst",
      getter: (data: Service, ref: CsvRef) =>
        getDoubleDisplayTextOrId(
          data.doorServices1Id,
          data.doorServices2Id,
          ref.doorServices,
          getDoorServiceDisplayText
        ),
    },
    {
      column: "coffeeServicesId",
      title: "Koffiedienst",
      getter: (data: Service, ref: CsvRef) =>
        getDoubleDisplayTextOrId(
          data.coffeeServices1Id,
          data.coffeeServices2Id,
          ref.coffeeServices,
          getCoffeeServiceDisplayText
        ),
    },
    {
      column: "audioMembersId",
      title: "Audio team",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(
          data.audioMembersId,
          ref.audioMembers,
          getAudioMemberDisplayText
        ),
    },
    {
      column: "clerksId",
      title: "Koster",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(data.clerksId, ref.clerks, getClerkDisplayText),
    },
    {
      column: "publicationsId",
      title: "Kerkblad publicatie",
      getter: (data: Service, ref: CsvRef) =>
        getDisplayTextOrId(
          data.publicationsId,
          ref.publications,
          getPublicationDisplayText
        ),
    },
    {
      column: "cleanersId",
      title: "Schoonmaker",
      getter: (data: Service, ref: CsvRef) =>
        getDoubleDisplayTextOrId(
          data.cleaners1Id,
          data.cleaners2Id,
          ref.cleaners,
          getCleanerDisplayText
        ),
    },
    {
      column: "babysittersId",
      title: "Oppas",
      getter: (data: Service, ref: CsvRef) =>
      getTrippleDisplayTextOrId(
          data.babysitters1Id,
          data.babysitters2Id,
          data.babysitters3Id,
          ref.babysitters,
          getBabysitterDisplayText
        ),
    },
    {
      column: "firstCollection",
      title: "1",
      getter: (data: Service) => collectionText(data.firstCollection),
    },
    {
      column: "secondCollection",
      title: "2",
      getter: (data: Service) => collectionText(data.secondCollection),
    },
    {
      column: "collectionBox",
      title: "O",
      getter: (data: Service) => collectionText(data.collectionBox),
    },
    {
      column: "extraCollection",
      title: "E",
      getter: (data: Service) => collectionText(data.extraCollection),
    },
    {
      column: "collectionGoal",
      title: "Bestemming",
      getter: (data: Service) => data.collectionGoal,
    },
  ];
  protected csvFilename = "Diensten";

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string
  ) {
    super(
      http,
      authenticationService,
      `${backendUrl}services`,
      RightModule.Service
    );
  }

  jsonToData(json: ServiceJson) {
    return serviceJsonToService(json);
  }

  dataToJson(data: Service) {
    let hours = 0;
    let minutes = 0;
    let timeSplit = data.time.split(":");
    if (timeSplit.length === 2) {
      hours = parseInt(timeSplit[0]) || hours;
      minutes = parseInt(timeSplit[1]) || minutes;
    }
    return {
      ...data,
      date: undefined,
      time: undefined,
      dateTime: new Date(
        data.date.getFullYear(),
        data.date.getMonth(),
        data.date.getDate(),
        hours,
        minutes
      ).getTime(),
      hasLiturgy: data.hasLiturgy ? 1 : 0,
      archived: data.archived ? 1 : 0,
    };
  }

  delete(
    data: Service,
    callbackConfirm: (deleteInfo: Service[], confirm: () => void) => void,
    callbackFinished?: (dataId: number) => void
  ) {
    callbackConfirm([], () => {
      this.deleteForce(data, callbackFinished);
    });
  }

  protected handleReferences(references: DataReferences) {}

  updateReferences(services: Service[]) {
    if (services.length > 0) {
      this._data.next([
        ...this._data.value.filter(
          (value) => !services.find((service) => service.id === value.id)
        ),
        ...services,
      ]);
    }
  }
}
