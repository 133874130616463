import { Data, JsonData } from "src/app/shared/data";

export interface DoorServiceJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface DoorService extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export function doorServiceJsonToDoorService(
  json: DoorServiceJson
): DoorService {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getDoorServiceDisplayText(
  doorService: DoorService,
  lastNameFirst = true
) {
  const result = `${doorService.firstName} ${doorService.middleName}`;
  if (lastNameFirst) {
    return `${doorService.lastName}, ${result}`;
  }
  return `${result} ${doorService.lastName}`;
}
