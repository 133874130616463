import { PipeTransform, Pipe } from "@angular/core";
import { Publication } from "src/app/shared/publication-service/publication";
import { formatDate } from "src/app/shared/util/util";

@Pipe({ name: "publicationWeekNumber", pure: true })
export class PublicationWeekNumberPipe implements PipeTransform {
  transform(publication: Publication) {
    return getWeekNumber(publication.dueDate);
  }
}

export function getWeekNumber(date: Date) {
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const dayNum = utcDate.getUTCDay() || 7;
  utcDate.setUTCDate(utcDate.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));
  return Math.ceil(
    ((utcDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
}
