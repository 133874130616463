import { PipeTransform, Pipe } from "@angular/core";
import {
  Publication,
  getPublicationDate,
} from "src/app/shared/publication-service/publication";
import { formatDate } from "src/app/shared/util/util";

@Pipe({ name: "formatPublicationDate", pure: true })
export class FormatPublicationDatePipe implements PipeTransform {
  transform(publication: Publication) {
    return formatDate(getPublicationDate(publication));
  }
}
