import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, of, combineLatest } from "rxjs";
import { ServerResult } from "src/app/shared/server-result";
import { JsonData, Data } from "src/app/shared/data";
import {
  serviceJsonToService,
  ServiceJson,
  Service,
} from "src/app/shared/service-service/service";
import {
  DataReferences,
  DataReferencesJson,
  dataReferencesJsonToDataReferences,
} from "src/app/shared/data-references";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { distinctUntilChanged, switchMap, filter, map } from "rxjs/operators";
import { cpuUsage } from "process";

export interface SingleDataServerResult<T extends JsonData>
  extends ServerResult {
  data: T;
}

export interface MultiDataServerResult<T extends JsonData>
  extends ServerResult {
  data: T[];
}

export interface DeleteServerResult extends ServerResult {
  data: {
    deletedId: number;
    changedReferences: DataReferencesJson;
  };
}

export abstract class DataService<
  TJson extends JsonData,
  TData extends Data,
  TCsvRef
> {
  protected abstract readonly csvFilename: string;
  protected abstract readonly csvColumnValueGetter: Array<{
    column: string;
    title: string;
    getter: (data: TData, ref: TCsvRef | undefined) => string;
  }>;

  private readonly archiveExceptions = new BehaviorSubject<number[]>([]);

  protected readonly _data = new BehaviorSubject<TData[]>([]);
  readonly referenceData$ = this._data.asObservable();
  readonly data$ = combineLatest(
    this.referenceData$,
    this.authentication.getRightLevel(RightModule.Admin),
    this.archiveExceptions
  ).pipe(
    map(([referenceData, rightLevel, archiveExceptions]) =>
      rightLevel >= RightLevel.Read
        ? referenceData
        : referenceData.filter(
            (data) => !data.archived || archiveExceptions.indexOf(data.id) >= 0
          )
    )
  );

  constructor(
    private readonly http: HttpClient,
    private readonly authentication: AuthenticationService,
    private readonly controlUrl: string,
    private readonly rightModule: RightModule
  ) {
    this.authentication
      .getRightLevel(this.rightModule)
      .pipe(
        distinctUntilChanged(),
        switchMap((rightLevel) => {
          if (rightLevel > RightLevel.None) {
            return this.http.get<MultiDataServerResult<TJson>>(
              this.controlUrl,
              this.authentication.getHttpOptions()
            );
          } else {
            return of({
              data: [],
              statusCode: -1,
            } as MultiDataServerResult<TJson>);
          }
        })
      )
      .subscribe((serverResult) => {
        this._data.next(
          serverResult.data.map((value) => {
            return this.jsonToData(value);
          })
        );
      });
  }

  abstract jsonToData(json: TJson): TData;
  abstract dataToJson(data: TData): TJson;

  save(data: TData, callback?: (data: TData) => void) {
    if (data.id === 0) {
      // Create new
      this.http
        .post<SingleDataServerResult<TJson>>(
          this.controlUrl,
          this.dataToJson(data),
          this.authentication.getHttpOptions()
        )
        .subscribe((serverResult) => {
          const newData = this.jsonToData(serverResult.data);
          this._data.next([...this._data.value, newData]);
          if (callback) {
            callback(newData);
          }
        });
    } else {
      // Update existing
      this.http
        .put<SingleDataServerResult<TJson>>(
          `${this.controlUrl}/${data.id}`,
          this.dataToJson(data),
          this.authentication.getHttpOptions()
        )
        .subscribe((serverResult) => {
          const updatedData = this.jsonToData(serverResult.data);
          this._data.next([
            ...this._data.value.filter((value) => value.id !== data.id),
            updatedData,
          ]);
          if (callback) {
            callback(updatedData);
          }
        });
    }
  }

  archive(data: TData, callback?: (data: TData) => void) {
    if (data.archived && this.archiveExceptions.value.indexOf(data.id) === -1) {
      this.archiveExceptions.next([...this.archiveExceptions.value, data.id]);
    }
    this.http
      .put<SingleDataServerResult<TJson>>(
        `${this.controlUrl}/Archive/${data.id}`,
        this.dataToJson(data),
        this.authentication.getHttpOptions()
      )
      .subscribe((serverResult) => {
        const updatedData = this.jsonToData(serverResult.data);
        this._data.next([
          ...this._data.value.filter((value) => value.id !== data.id),
          updatedData,
        ]);
        if (callback) {
          callback(updatedData);
        }
      });
  }

  delete(
    data: TData,
    callbackConfirm: (deleteInfo: Service[], confirm: () => void) => void,
    callbackFinished?: (dataId: number) => void
  ) {
    this.http
      .get<MultiDataServerResult<ServiceJson>>(
        `${this.controlUrl}/DeleteInfo/${data.id}`,
        this.authentication.getHttpOptions()
      )
      .subscribe((serverResult) => {
        callbackConfirm(
          serverResult.data.map((value) => {
            return serviceJsonToService(value);
          }),
          () => {
            this.deleteForce(data, callbackFinished);
          }
        );
      });
  }

  deleteForce(data: TData, callback?: (dataId: number) => void) {
    this.http
      .delete<DeleteServerResult>(
        `${this.controlUrl}/${data.id}`,
        this.authentication.getHttpOptions()
      )
      .subscribe((serverResult) => {
        this._data.next([
          ...this._data.value.filter(
            (value) => value.id !== serverResult.data.deletedId
          ),
        ]);
        this.handleReferences(
          dataReferencesJsonToDataReferences(
            serverResult.data.changedReferences
          )
        );
        if (callback) {
          callback(serverResult.data.deletedId);
        }
      });
  }

  protected abstract handleReferences(references: DataReferences): void;

  downloadCsv(objects: TData[], ref: TCsvRef, columns: string[]) {
    const rows: string[] = [];

    // Create header row
    let header = "";
    for (const { column, title } of this.csvColumnValueGetter) {
      if (columns.includes(column)) {
        if (header.length > 0) {
          header += this.authentication.csvSeparator;
        }
        header += `"${title}"`;
      }
    }
    rows.push(header);

    // Create content rows
    for (const object of objects) {
      let row = "";
      for (const { column, getter } of this.csvColumnValueGetter) {
        if (columns.includes(column)) {
          if (row.length > 0) {
            row += this.authentication.csvSeparator;
          }
          row += `"${getter(object, ref)}"`;
        }
      }
      rows.push(row);
    }

    // Create content
    const csvContent = rows.join("\n");

    // Create download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, `${this.csvFilename}.csv`);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${this.csvFilename}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
