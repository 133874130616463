import { Data, JsonData } from "src/app/shared/data";

export interface CoffeeServiceJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface CoffeeService extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export function coffeeServiceJsonToCoffeeService(
  json: CoffeeServiceJson
): CoffeeService {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getCoffeeServiceDisplayText(
  coffeeService: CoffeeService,
  lastNameFirst = true
) {
  const result = `${coffeeService.firstName} ${coffeeService.middleName}`;
  if (lastNameFirst) {
    return `${coffeeService.lastName}, ${result}`;
  }
  return `${result} ${coffeeService.lastName}`;
}
