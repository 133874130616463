import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { Service } from "src/app/shared/service-service/service";
import { map, take, takeUntil, tap } from "rxjs/operators";
import {
  formatDate,
  isEqualDay,
  tableWidthCalculator,
} from "src/app/shared/util/util";
import {
  Collection,
  CollectionBox,
} from "src/app/shared/service-service/collection";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { DeleteConfirmDialogComponent } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { DeleteConfirmData } from "src/app/shared/delete-confirm-dialog/delete-confirm-data";
import { RefSource } from "src/app/shared/util/ref-source";
import { combineLatest, Observable, Subject } from "rxjs";
import { CarServiceService } from "src/app/shared/car-service-service/car-service.service";
import { DoorServiceService } from "src/app/shared/door-service-service/door-service.service";
import { CoffeeServiceService } from "src/app/shared/coffee-service-service/coffee-service.service";
import { PreacherService } from "src/app/shared/preacher-service/preacher.service";
import { PublicationService } from "src/app/shared/publication-service/publication.service";
import { ClerkService } from "src/app/shared/clerk-service/clerk.service";
import { CleanerService } from "src/app/shared/cleaner-service/cleaner.service";
import { OrganistService } from "src/app/shared/organist-service/organist.service";
import { ElderService } from "src/app/shared/elder-service/elder.service";
import {
  CarService,
  getCarServiceDisplayText,
} from "src/app/shared/car-service-service/car-service";
import {
  DoorService,
  getDoorServiceDisplayText,
} from "src/app/shared/door-service-service/door-service";
import {
  CoffeeService,
  getCoffeeServiceDisplayText,
} from "src/app/shared/coffee-service-service/coffee-service";
import {
  AudioMember,
  getAudioMemberDisplayText,
} from "src/app/shared/audio-member-service/audio-member";
import {
  Preacher,
  getPreacherDisplayText,
} from "src/app/shared/preacher-service/preacher";
import {
  Publication,
  getPublicationDisplayText,
} from "src/app/shared/publication-service/publication";
import { Clerk, getClerkDisplayText } from "src/app/shared/clerk-service/clerk";
import {
  Cleaner,
  getCleanerDisplayText,
} from "src/app/shared/cleaner-service/cleaner";
import {
  Organist,
  getOrganistDisplayText,
} from "src/app/shared/organist-service/organist";
import { Elder, getElderDisplayText } from "src/app/shared/elder-service/elder";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { FilterService } from "src/app/shared/filter/filter.service";
import { AudioMemberService } from "src/app/shared/audio-member-service/audio-member.service";
import { BabysitterService } from "../shared/babysitter-service/babysitter.service";
import {
  Babysitter,
  getBabysitterDisplayText,
} from "../shared/babysitter-service/babysitter";
import { NewServiceDialogComponent } from "../shared/new-service-dialog/new-service-dialog.component";

@Component({
  selector: "boezemkerk-diensten",
  templateUrl: "./diensten.component.html",
  styleUrls: ["./diensten.component.scss"],
})
export class DienstenComponent implements OnInit, OnDestroy {
  private readonly displayColumns$: Observable<string[]>;
  displayColumns: string[] = [];
  readonly tableWidth$: Observable<string>;

  readonly selectedServices: number[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly dataSource: RefSource<
    Service,
    {
      carServices: CarService[];
      doorServices: DoorService[];
      coffeeServices: CoffeeService[];
      audioMembers: AudioMember[];
      preachers: Preacher[];
      publications: Publication[];
      clerks: Clerk[];
      cleaners: Cleaner[];
      babysitters: Babysitter[];
      organists: Organist[];
      elders: Elder[];
    }
  >;

  // readonly services$ = this.service.data$.pipe(
  //   tap((services) => {
  //     const selected = [...this.selectedServices];
  //     this.selectedServices.splice(0, this.selectedServices.length);
  //     this.selectedServices.push(
  //       ...selected.filter((selectedId) =>
  //         services.find((service) => service.id === selectedId)
  //       )
  //     );
  //   }),
  //   map((services) =>
  //     [...services].sort((a, b) => {
  //       const aTimeSplit = a.time.split(":");
  //       const aHours = aTimeSplit.length === 2 ? parseInt(aTimeSplit[0]) : 0;
  //       const aMinutes = aTimeSplit.length === 2 ? parseInt(aTimeSplit[1]) : 0;
  //       const bTimeSplit = b.time.split(":");
  //       const bHours = bTimeSplit.length === 2 ? parseInt(bTimeSplit[0]) : 0;
  //       const bMinutes = bTimeSplit.length === 2 ? parseInt(bTimeSplit[1]) : 0;
  //       return (
  //         b.date.getTime() +
  //         bHours * 60 +
  //         bMinutes -
  //         (a.date.getTime() + aHours * 60 + aMinutes)
  //       );
  //     })
  //   )
  // );

  readonly nextDate$: Observable<Date>;

  readonly RightLevel = RightLevel;
  readonly rightLevel: Observable<RightLevel>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly service: ServiceService,
    private readonly carService: CarServiceService,
    private readonly doorService: DoorServiceService,
    private readonly coffeeService: CoffeeServiceService,
    private readonly audioMember: AudioMemberService,
    private readonly preacher: PreacherService,
    private readonly publication: PublicationService,
    private readonly clerk: ClerkService,
    private readonly cleaner: CleanerService,
    private readonly babysitter: BabysitterService,
    private readonly organist: OrganistService,
    private readonly elder: ElderService,
    private readonly dialog: MatDialog,
    authenticationService: AuthenticationService,
    private readonly filterService: FilterService
  ) {
    this.rightLevel = authenticationService.getRightLevel(RightModule.Service);
    this.displayColumns$ = createDisplayColumnsObservable(
      activatedRoute,
      authenticationService
    );
    this.tableWidth$ = createTableWidthObservable(this.displayColumns$);
    this.displayColumns$
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayColumns) => (this.displayColumns = displayColumns));

    const ref1To6 = combineLatest([
      this.carService.referenceData$,
      this.doorService.referenceData$,
      this.coffeeService.referenceData$,
      this.audioMember.referenceData$,
      this.preacher.referenceData$,
      this.publication.referenceData$,
    ]).pipe(
      map(
        ([
          carServices,
          doorServices,
          coffeeServices,
          audioMembers,
          preachers,
          publications,
        ]) => ({
          carServices,
          doorServices,
          coffeeServices,
          audioMembers,
          preachers,
          publications,
        })
      )
    );
    const ref7To11 = combineLatest([
      this.clerk.referenceData$,
      this.cleaner.referenceData$,
      this.babysitter.referenceData$,
      this.organist.referenceData$,
      this.elder.referenceData$,
    ]).pipe(
      map(([clerks, cleaners, babysitters, organists, elders]) => ({
        clerks,
        cleaners,
        babysitters,
        organists,
        elders,
      }))
    );

    this.dataSource = new RefSource(
      combineLatest([this.service.data$, this.filterService.data$]).pipe(
        map(([services, filterData]) => {
          return services.filter((service) => {
            if (filterData.from && service.date < filterData.from) {
              return false;
            }
            if (filterData.to) {
              const nextDay = new Date(filterData.to);
              nextDay.setDate(nextDay.getDate() + 1);
              if (service.date >= nextDay) {
                return false;
              }
            }
            return true;
          });
        }),
        tap((services) => {
          const selected = [...this.selectedServices];
          this.selectedServices.splice(0, this.selectedServices.length);
          this.selectedServices.push(
            ...selected.filter((selectedId) =>
              services.find((service) => service.id === selectedId)
            )
          );
        })
      ),
      DataSourceSort,
      { active: "dateTime", direction: "asc" },
      combineLatest([ref1To6, ref7To11]).pipe(
        map(([group1, group2]) => ({ ...group1, ...group2 }))
      )
    );

    this.nextDate$ = this.dataSource.data$.pipe(
      map((services) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let nextDate: Date | undefined;
        for (const service of services) {
          if (service.date >= today && (!nextDate || service.date < nextDate)) {
            nextDate = service.date;
            if (isEqualDay(nextDate, today)) {
              break;
            }
          }
        }
        return nextDate;
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.dataSource.setSort(this.sort.sortChange);
  }

  download() {
    combineLatest([this.dataSource.dataAndRef$, this.displayColumns$])
      .pipe(take(1))
      .subscribe(([{ data, ref }, displayColumns]) => {
        this.service.downloadCsv(data, ref, displayColumns);
      });
  }

  addService() {
    this.dialog
      .open<
        NewServiceDialogComponent,
        void,
        { date: Date; time: string } | undefined
      >(NewServiceDialogComponent, { disableClose: true })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.service.save({
            id: 0,
            ...result,
            song: "",
            specialGuest: "",
            hasLiturgy: false,
            reading: undefined,
            mainText: undefined,
            theme: undefined,
            announcements: "",
            preachersId: undefined,
            eldersId: undefined,
            elderDetail: "",
            organistsId: undefined,
            carServicesId: undefined,
            doorServices1Id: undefined,
            doorServices2Id: undefined,
            clerksId: undefined,
            publicationsId: undefined,
            cleaners1Id: undefined,
            cleaners2Id: undefined,
            coffeeServices1Id: undefined,
            coffeeServices2Id: undefined,
            audioMembersId: undefined,
            babysitters1Id: undefined,
            babysitters2Id: undefined,
            babysitters3Id: undefined,
            firstCollection: Collection.None,
            secondCollection: Collection.None,
            collectionBox: CollectionBox.None,
            extraCollection: Collection.None,
            collectionGoal: "",
            remark: "",
            archived: false,
          });
          return;
        }
      });
  }

  archiveService(service: Service) {
    this.service.archive({ ...service, archived: !service.archived });
  }

  removeService(service: Service) {
    this.service.delete(service, (deleteInfo, confirm) => {
      this.dialog
        .open<DeleteConfirmDialogComponent, DeleteConfirmData, boolean>(
          DeleteConfirmDialogComponent,
          {
            data: {
              title: `Dienst: ${formatDate(service.date)} ${service.time}`,
              objectName: "dienst",
              deleteInfo,
            },
          }
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            confirm();
          }
        });
    });
  }

  rowSelect(service: Service) {
    const index = this.selectedServices.indexOf(service.id);

    if (this.selectedServices.length <= 1) {
      this.selectedServices.splice(0, this.selectedServices.length);
      if (index !== 0) {
        this.selectedServices.push(service.id);
      }
      return;
    }

    if (index >= 0) {
      this.selectedServices.splice(index, 1);
    } else {
      this.selectedServices.push(service.id);
    }
  }

  rowMultiSelect(service: Service, event: MouseEvent) {
    event.preventDefault();
    if (this.selectedServices.indexOf(service.id) === -1) {
      this.selectedServices.push(service.id);
    }
  }
}

function DataSourceSort(
  a: Service,
  b: Service,
  sort: Sort,
  ref: {
    carServices: CarService[];
    doorServices: DoorService[];
    coffeeServices: CoffeeService[];
    audioMembers: AudioMember[];
    preachers: Preacher[];
    publications: Publication[];
    clerks: Clerk[];
    cleaners: Cleaner[];
    babysitters: Babysitter[];
    organists: Organist[];
    elders: Elder[];
  }
) {
  let value = 0;
  switch (sort.active) {
    case "dateTime":
      const aTimeSplit = a.time.split(":");
      const aHours = aTimeSplit.length === 2 ? parseInt(aTimeSplit[0]) : 0;
      const aMinutes = aTimeSplit.length === 2 ? parseInt(aTimeSplit[1]) : 0;
      const bTimeSplit = b.time.split(":");
      const bHours = bTimeSplit.length === 2 ? parseInt(bTimeSplit[0]) : 0;
      const bMinutes = bTimeSplit.length === 2 ? parseInt(bTimeSplit[1]) : 0;
      value =
        a.date.getTime() +
        aHours * 60 +
        aMinutes -
        (b.date.getTime() + bHours * 60 + bMinutes);
      break;
    case "remark":
      value = a.remark.localeCompare(b.remark);
      break;
    case "preachersId":
      const preacherA = ref.preachers.find(
        (preacher) => preacher.id === a.preachersId
      );
      const preacherB = ref.preachers.find(
        (preacher) => preacher.id === b.preachersId
      );
      value = (
        preacherA ? getPreacherDisplayText(preacherA, false) : ""
      ).localeCompare(
        preacherB ? getPreacherDisplayText(preacherB, false) : ""
      );
      break;
    case "song":
      value = a.remark.localeCompare(b.remark);
      break;
    case "specialGuest":
      value = a.remark.localeCompare(b.remark);
      break;
    case "eldersId":
      const elderA = ref.elders.find((elder) => elder.id === a.eldersId);
      const elderB = ref.elders.find((elder) => elder.id === b.eldersId);
      value = (
        elderA ? getElderDisplayText(elderA, false, a.elderDetail) : ""
      ).localeCompare(
        elderB ? getElderDisplayText(elderB, false, b.elderDetail) : ""
      );
      break;
    case "organistsId":
      const organistA = ref.organists.find(
        (organist) => organist.id === a.organistsId
      );
      const organistB = ref.organists.find(
        (organist) => organist.id === b.organistsId
      );
      value = (
        organistA ? getOrganistDisplayText(organistA, false) : ""
      ).localeCompare(
        organistB ? getOrganistDisplayText(organistB, false) : ""
      );
      break;
    case "carServicesId":
      const carServiceA = ref.carServices.find(
        (carService) => carService.id === a.carServicesId
      );
      const carServiceB = ref.carServices.find(
        (carService) => carService.id === b.carServicesId
      );
      value = (
        carServiceA ? getCarServiceDisplayText(carServiceA, false) : ""
      ).localeCompare(
        carServiceB ? getCarServiceDisplayText(carServiceB, false) : ""
      );
      break;
    case "doorServicesId":
      const doorService1A = ref.doorServices.find(
        (doorService) => doorService.id === a.doorServices1Id
      );
      const doorService1B = ref.doorServices.find(
        (doorService) => doorService.id === b.doorServices1Id
      );
      const doorService2A = ref.doorServices.find(
        (doorService) => doorService.id === a.doorServices2Id
      );
      const doorService2B = ref.doorServices.find(
        (doorService) => doorService.id === b.doorServices2Id
      );
      value = (
        doorService1A ? getDoorServiceDisplayText(doorService1A, false) : ""
      ).localeCompare(
        doorService1B ? getDoorServiceDisplayText(doorService1B, false) : ""
      );
      if (value === 0) {
        value = (
          doorService2A ? getDoorServiceDisplayText(doorService2A, false) : ""
        ).localeCompare(
          doorService2B ? getDoorServiceDisplayText(doorService2B, false) : ""
        );
      }
      break;
    case "coffeeServicesId":
      const coffeeService1A = ref.coffeeServices.find(
        (coffeeService) => coffeeService.id === a.coffeeServices1Id
      );
      const coffeeService1B = ref.coffeeServices.find(
        (coffeeService) => coffeeService.id === b.coffeeServices1Id
      );
      const coffeeService2A = ref.coffeeServices.find(
        (coffeeService) => coffeeService.id === a.coffeeServices2Id
      );
      const coffeeService2B = ref.coffeeServices.find(
        (coffeeService) => coffeeService.id === b.coffeeServices2Id
      );
      value = (
        coffeeService1A
          ? getCoffeeServiceDisplayText(coffeeService1A, false)
          : ""
      ).localeCompare(
        coffeeService1B
          ? getCoffeeServiceDisplayText(coffeeService1B, false)
          : ""
      );
      if (value === 0) {
        value = (
          coffeeService2A
            ? getCoffeeServiceDisplayText(coffeeService2A, false)
            : ""
        ).localeCompare(
          coffeeService2B
            ? getCoffeeServiceDisplayText(coffeeService2B, false)
            : ""
        );
      }
      break;
    case "audioMembersId":
      const audioMemberA = ref.audioMembers.find(
        (audioMember) => audioMember.id === a.audioMembersId
      );
      const audioMemberB = ref.audioMembers.find(
        (audioMember) => audioMember.id === a.audioMembersId
      );
      value = (
        audioMemberA ? getAudioMemberDisplayText(audioMemberA, false) : ""
      ).localeCompare(
        audioMemberB ? getAudioMemberDisplayText(audioMemberB, false) : ""
      );
      break;
    case "clerksId":
      const clerkA = ref.clerks.find((clerk) => clerk.id === a.clerksId);
      const clerkB = ref.clerks.find((clerk) => clerk.id === b.clerksId);
      value = (clerkA ? getClerkDisplayText(clerkA, false) : "").localeCompare(
        clerkB ? getClerkDisplayText(clerkB, false) : ""
      );
      break;
    case "publicationsId":
      const publicationA = ref.publications.find(
        (publication) => publication.id === a.publicationsId
      );
      const publicationB = ref.publications.find(
        (publication) => publication.id === b.publicationsId
      );
      value = (
        publicationA ? getPublicationDisplayText(publicationA) : ""
      ).localeCompare(
        publicationB ? getPublicationDisplayText(publicationB) : ""
      );
      break;
    case "cleanersId":
      const cleaner1A = ref.cleaners.find(
        (cleaner) => cleaner.id === a.cleaners1Id
      );
      const cleaner1b = ref.cleaners.find(
        (cleaner) => cleaner.id === b.cleaners1Id
      );
      const cleaner2A = ref.cleaners.find(
        (cleaner) => cleaner.id === a.cleaners2Id
      );
      const cleaner2b = ref.cleaners.find(
        (cleaner) => cleaner.id === b.cleaners2Id
      );
      value = (
        cleaner1A ? getCleanerDisplayText(cleaner1A, false) : ""
      ).localeCompare(cleaner1b ? getCleanerDisplayText(cleaner1b, false) : "");
      if (value === 0) {
        value = (
          cleaner2A ? getCleanerDisplayText(cleaner2A, false) : ""
        ).localeCompare(
          cleaner2b ? getCleanerDisplayText(cleaner2b, false) : ""
        );
      }
      break;
    case "babysitters1Id":
      const babysitter1A = ref.babysitters.find(
        (babysitter) => babysitter.id === a.babysitters1Id
      );
      const babysitter1b = ref.babysitters.find(
        (babysitter) => babysitter.id === b.babysitters1Id
      );
      value = (
        babysitter1A ? getBabysitterDisplayText(babysitter1A, false) : ""
      ).localeCompare(
        babysitter1b ? getBabysitterDisplayText(babysitter1b, false) : ""
      );
      break;
    case "babysitters2Id":
      const babysitter2A = ref.babysitters.find(
        (babysitter) => babysitter.id === a.babysitters2Id
      );
      const babysitter2b = ref.babysitters.find(
        (babysitter) => babysitter.id === b.babysitters2Id
      );
      value = (
        babysitter2A ? getBabysitterDisplayText(babysitter2A, false) : ""
      ).localeCompare(
        babysitter2b ? getBabysitterDisplayText(babysitter2b, false) : ""
      );
      break;
    case "babysitters3Id":
      const babysitter3A = ref.babysitters.find(
        (babysitter) => babysitter.id === a.babysitters3Id
      );
      const babysitter3b = ref.babysitters.find(
        (babysitter) => babysitter.id === b.babysitters3Id
      );
      value = (
        babysitter3A ? getBabysitterDisplayText(babysitter3A, false) : ""
      ).localeCompare(
        babysitter3b ? getBabysitterDisplayText(babysitter3b, false) : ""
      );
      break;
    case "firstCollection":
      value = a.firstCollection - b.firstCollection;
      break;
    case "secondCollection":
      value = a.secondCollection - b.secondCollection;
      break;
    case "collectionBox":
      value = a.collectionBox - b.collectionBox;
      break;
    case "extraCollection":
      value = a.extraCollection - b.extraCollection;
      break;
    case "collectionGoal":
      value = a.collectionGoal.localeCompare(b.collectionGoal);
      break;
    default:
      value = a.id - b.id;
  }
  return value * (sort.direction === "desc" ? -1 : 1);
}

function createDisplayColumnsObservable(
  activatedRoute: ActivatedRoute,
  authentication: AuthenticationService
) {
  return combineLatest([
    activatedRoute.paramMap,
    authentication.getRightLevel(RightModule.Service),
    authentication.getRightLevel(RightModule.ServiceGeneral),
    authentication.getRightLevel(RightModule.CarService),
    authentication.getRightLevel(RightModule.Clerk),
    authentication.getRightLevel(RightModule.Cleaner),
    authentication.getRightLevel(RightModule.Babysitter),
    authentication.getRightLevel(RightModule.Collection),
    authentication.getRightLevel(RightModule.DoorService),
    authentication.getRightLevel(RightModule.CoffeeService),
    authentication.getRightLevel(RightModule.AudioMember),
    authentication.getRightLevel(RightModule.Elder),
    authentication.getRightLevel(RightModule.Organist),
    authentication.getRightLevel(RightModule.Preacher),
    authentication.getRightLevel(RightModule.Publication),
  ]).pipe(
    map<
      [
        ParamMap,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel,
        RightLevel
      ],
      string[]
    >(
      ([
        paramMap,
        serviceRightLevel,
        serviceGeneralRightLevel,
        carServiceRightLevel,
        clerkRightLevel,
        cleanerRightLevel,
        babysitterRightLevel,
        collectionRightLevel,
        doorServiceRightLevel,
        coffeeServiceRightLevel,
        audioMemberRightLevel,
        elderRightLevel,
        organistRightLevel,
        preacherRightLevel,
        publicationRightLevel,
      ]) => {
        const type = paramMap.get("type");
        const result = ["filter", "dateTime"];
        if (serviceGeneralRightLevel > RightLevel.None) {
          result.push("remark");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "kc",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          preacherRightLevel > RightLevel.None
        ) {
          result.push("preachersId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type)
        ) {
          result.push("song");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "dominees",
            "kc",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          audioMemberRightLevel > RightLevel.None
        ) {
          result.push("audioMembersId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type)
        ) {
          result.push("specialGuest");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "tijden",
          ].includes(type) &&
          elderRightLevel > RightLevel.None
        ) {
          result.push("eldersId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "schoonmakers",
            "oppas",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          organistRightLevel > RightLevel.None
        ) {
          result.push("organistsId");
        }
        if (
          ![
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          carServiceRightLevel > RightLevel.None
        ) {
          result.push("carServicesId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          doorServiceRightLevel > RightLevel.None
        ) {
          result.push("doorServicesId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          coffeeServiceRightLevel > RightLevel.None
        ) {
          result.push("coffeeServicesId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kosters",
            "schoonmakers",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          babysitterRightLevel > RightLevel.None
        ) {
          result.push("babysitters1Id");
          result.push("babysitters2Id");
          result.push("babysitters3Id");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "schoonmakers",
            "oppaso",
            "rganisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          clerkRightLevel > RightLevel.None
        ) {
          result.push("clerksId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          publicationRightLevel > RightLevel.None
        ) {
          result.push("publicationsId");
        }
        if (
          ![
            "autodienst",
            "collecten",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          cleanerRightLevel > RightLevel.None
        ) {
          result.push("cleanersId");
        }
        if (
          ![
            "autodienst",
            "deurdienst",
            "koffiedienst",
            "audioteam",
            "dominees",
            "kc",
            "kopij",
            "kosters",
            "schoonmakers",
            "oppas",
            "organisten",
            "ouderlingen",
            "tijden",
          ].includes(type) &&
          collectionRightLevel > RightLevel.None
        ) {
          result.push("firstCollection");
          result.push("secondCollection");
          result.push("collectionBox");
          result.push("extraCollection");
          result.push("collectionGoal");
        }
        result.push("download");
        if (serviceRightLevel >= RightLevel.Adjust) {
          result.push("actionAdjust");
        }
        if (serviceRightLevel >= RightLevel.Archive) {
          result.push("actionArchive");
        }
        if (serviceRightLevel >= RightLevel.Delete) {
          result.push("actionDelete");
        }
        return result;
      }
    )
  );
}

function createTableWidthObservable(displayColumns$: Observable<string[]>) {
  return displayColumns$.pipe(
    map(
      tableWidthCalculator({
        dateTime: 110,
        remark: 140,
        preachersId: 170,
        song: 110,
        specialGuest: 140,
        eldersId: 125,
        organistsId: 125,
        carServicesId: 140,
        doorServicesId: 140,
        coffeeServicesId: 140,
        audioMembersId: 125,
        clerksId: 90,
        publicationsId: 20,
        cleanersId: 140,
        babysitters1Id: 140,
        babysitters2Id: 140,
        babysitters3Id: 140,
        firstCollection: 20,
        secondCollection: 20,
        collectionBox: 20,
        extraCollection: 20,
        collectionGoal: 300,
        download: 40,
        actionAdjust: 40,
        actionArchive: 40,
        actionDelete: 40,
      })
    )
  );
}
