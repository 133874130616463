import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  getPreacherDisplayText,
  Preacher,
  PreacherJson,
  preacherJsonToPreacher
} from "src/app/shared/preacher-service/preacher";
import { DataService } from "src/app/shared/data-service";
import { DataReferences } from "src/app/shared/data-references";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { RightModule } from "src/app/shared/authentication-service/right-module";

@Injectable()
export class PreacherService extends DataService<PreacherJson, Preacher, undefined> {
  protected csvColumnValueGetter = [
    {column: "name", title: "Naam", getter: (data: Preacher) => getPreacherDisplayText(data)},
    {column: "location", title: "Locatie", getter: (data: Preacher) => data.location},
    {column: "phone", title: "Telefoon", getter: (data: Preacher) => data.phone},
    {column: "email", title: "Email", getter: (data: Preacher) => data.email},
    {column: "remark", title: "Opmerkingen", getter: (data: Preacher) => data.remark}
  ];
  protected csvFilename = "Dominees";

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string,
    private readonly serviceService: ServiceService
  ) {
    super(
      http,
      authenticationService,
      `${backendUrl}preachers`,
      RightModule.Preacher
    );
  }

  jsonToData(json: PreacherJson) {
    return preacherJsonToPreacher(json);
  }

  dataToJson(data: Preacher) {
    return {
      ...data,
      archived: data.archived ? 1 : 0
    };
  }

  protected handleReferences(references: DataReferences) {
    if (references.services) {
      this.serviceService.updateReferences(references.services);
    }
  }
}
