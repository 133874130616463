import { Data, JsonData } from "src/app/shared/data";

export interface CarServiceJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
}

export interface CarService extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
}

export function carServiceJsonToCarService(json: CarServiceJson): CarService {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getCarServiceDisplayText(
  carService: CarService,
  lastNameFirst = true
) {
  const result = `${carService.firstName} ${carService.middleName}`;
  if (lastNameFirst) {
    return `${carService.lastName}, ${result}`;
  }
  return `${result} ${carService.lastName}`;
}
