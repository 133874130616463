import { Observable } from "rxjs";
import { Sort } from "@angular/material/sort";
import { BaseSource } from "src/app/shared/util/base-source";
import { Data } from "../data";

export class RefSource<TData, TRef extends {[key: string]: Data[]}> extends BaseSource<
  TData,
  (a: TData, b: TData, sort: Sort, ref: TRef) => number,
  TRef
> {
  constructor(
    observable$: Observable<TData[]>,
    sort: (a: TData, b: TData, sort: Sort, ref: TRef) => number,
    defaultSort: Sort,
    references$: Observable<TRef>
  ) {
    super(observable$, sort, defaultSort, references$);
  }

  protected callSort(a: TData, b: TData, sort: Sort, ref: TRef) {
    return this.sort(a, b, sort, ref);
  }
}
