import { Component, Input } from "@angular/core";
import { AudioMemberService } from "src/app/shared/audio-member-service/audio-member.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { AudioMember } from "src/app/shared/audio-member-service/audio-member";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-audio-member-details",
  templateUrl: "./audio-member-details.component.html",
  styleUrls: ["./audio-member-details.component.scss"]
})
export class AudioMemberDetailsComponent {
  form = this.createForm(undefined);

  readonly audioMember$ = combineLatest(
    this.audioMemberService.data$,
    this.route.paramMap
  ).pipe(
    map(([audioMembers, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return audioMembers.find(audioMember => audioMember.id === id);
    }),
    tap(audioMember => {
      this.form = this.createForm(audioMember);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly audioMemberService: AudioMemberService,
    private readonly fb: FormBuilder
  ) {}

  createForm(audioMember?: AudioMember) {
    return this.fb.group({
      firstName: [audioMember ? audioMember.firstName : ""],
      middleName: [audioMember ? audioMember.middleName : ""],
      lastName: [audioMember ? audioMember.lastName : ""],
      phone: [audioMember ? audioMember.phone : ""],
      email: [audioMember ? audioMember.email : ""],
      remark: [audioMember ? audioMember.remark : ""]
    });
  }

  onSubmit(audioMember: AudioMember) {
    this.audioMemberService.save({ ...audioMember, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
