import { Component, Input } from "@angular/core";
import { OrganistService } from "src/app/shared/organist-service/organist.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Organist } from "src/app/shared/organist-service/organist";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-organist-details",
  templateUrl: "./organist-details.component.html",
  styleUrls: ["./organist-details.component.scss"]
})
export class OrganistDetailsComponent {
  form = this.createForm(undefined);

  readonly organist$ = combineLatest(
    this.organistService.data$,
    this.route.paramMap
  ).pipe(
    map(([organists, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return organists.find(organist => organist.id === id);
    }),
    tap(organist => {
      this.form = this.createForm(organist);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly organistService: OrganistService,
    private readonly fb: FormBuilder
  ) {}

  createForm(organist?: Organist) {
    return this.fb.group({
      firstName: [organist ? organist.firstName : ""],
      middleName: [organist ? organist.middleName : ""],
      lastName: [organist ? organist.lastName : ""],
      phone: [organist ? organist.phone : ""],
      email: [organist ? organist.email : ""],
      remark: [organist ? organist.remark : ""]
    });
  }

  onSubmit(organist: Organist) {
    this.organistService.save({ ...organist, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
