import { Component, Input } from "@angular/core";
import { DoorServiceService } from "src/app/shared/door-service-service/door-service.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { DoorService } from "src/app/shared/door-service-service/door-service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-door-service-details",
  templateUrl: "./door-service-details.component.html",
  styleUrls: ["./door-service-details.component.scss"]
})
export class DoorServiceDetailsComponent {
  form = this.createForm(undefined);

  readonly doorService$ = combineLatest(
    this.doorServiceService.data$,
    this.route.paramMap
  ).pipe(
    map(([doorServices, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return doorServices.find(doorService => doorService.id === id);
    }),
    tap(doorService => {
      this.form = this.createForm(doorService);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly doorServiceService: DoorServiceService,
    private readonly fb: FormBuilder
  ) {}

  createForm(doorService?: DoorService) {
    return this.fb.group({
      firstName: [doorService ? doorService.firstName : ""],
      middleName: [doorService ? doorService.middleName : ""],
      lastName: [doorService ? doorService.lastName : ""],
      remark: [doorService ? doorService.remark : ""]
    });
  }

  onSubmit(doorService: DoorService) {
    this.doorServiceService.save({ ...doorService, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
