import { Data, JsonData } from "src/app/shared/data";

export interface AudioMemberJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface AudioMember extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

export function audioMemberJsonToAudioMember(json: AudioMemberJson): AudioMember {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getAudioMemberDisplayText(
  audioMember: AudioMember,
  lastNameFirst = true
) {
  const result = `${audioMember.firstName} ${audioMember.middleName}`;
  let locationDisplayText = "";
  if (lastNameFirst) {
    return `${audioMember.lastName}, ${result}${locationDisplayText}`;
  }
  return `${result} ${audioMember.lastName}${locationDisplayText}`;
}
