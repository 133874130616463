import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AudioMemberService } from "src/app/shared/audio-member-service/audio-member.service";
import {
  AudioMember,
  getAudioMemberDisplayText,
} from "src/app/shared/audio-member-service/audio-member";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { DeleteConfirmDialogComponent } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { DeleteConfirmData } from "src/app/shared/delete-confirm-dialog/delete-confirm-data";
import { Source } from "src/app/shared/util/source";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { tableWidthCalculator } from "src/app/shared/util/util";
import { combineLatest, Subject } from "rxjs";

@Component({
  selector: "boezemkerk-audio-teams",
  templateUrl: "./audio-teams.component.html",
  styleUrls: ["./audio-teams.component.scss"],
})
export class AudioTeamsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly dataSource = new Source(
    this.audioMemberService.data$.pipe(
      tap((audioMembers) => {
        const selected = [...this.selectedAudioMembers];
        this.selectedAudioMembers.splice(0, this.selectedAudioMembers.length);
        this.selectedAudioMembers.push(
          ...selected.filter((selectedId) =>
            audioMembers.find((service) => service.id === selectedId)
          )
        );
      })
    ),
    DataSourceSort,
    {
      active: "name",
      direction: "asc",
    }
  );

  readonly selectedAudioMembers: number[] = [];

  readonly RightLevel = RightLevel;
  readonly rightLevel = this.authenticationService.getRightLevel(
    RightModule.AudioMember
  );

  private readonly displayColumns$ = this.rightLevel.pipe(
    map((rightLevel) => {
      const displayColumns = ["name", "phone", "email", "remark", "download"];
      if (rightLevel >= RightLevel.Adjust) {
        displayColumns.push("actionAdjust");
      }
      if (rightLevel >= RightLevel.Archive) {
        displayColumns.push("actionArchive");
      }
      if (rightLevel >= RightLevel.Delete) {
        displayColumns.push("actionDelete");
      }
      return displayColumns;
    })
  );
  displayColumns: string[] = [];
  readonly tableWidth$ = this.displayColumns$.pipe(
    map(
      tableWidthCalculator({
        name: 140,
        phone: 100,
        email: 256,
        remark: 140,
        download: 40,
        actionAdjust: 40,
        actionArchive: 40,
        actionDelete: 40,
      })
    )
  );

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly audioMemberService: AudioMemberService,
    private readonly dialog: MatDialog,
    private readonly authenticationService: AuthenticationService
  ) {
    this.displayColumns$
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayColumns) => (this.displayColumns = displayColumns));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.dataSource.setSort(this.sort.sortChange);
  }

  download() {
    combineLatest([this.dataSource.data$, this.displayColumns$])
      .pipe(take(1))
      .subscribe(([data, displayColumns]) => {
        this.audioMemberService.downloadCsv(data, undefined, displayColumns);
      });
  }

  addAudioMember() {
    this.audioMemberService.save({
      id: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      email: "",
      remark: "",
      archived: false,
    });
  }

  archiveAudioMember(audioMember: AudioMember) {
    this.audioMemberService.archive({
      ...audioMember,
      archived: !audioMember.archived,
    });
  }

  removeAudioMember(audioMember: AudioMember) {
    this.audioMemberService.delete(audioMember, (deleteInfo, confirm) => {
      this.dialog
        .open<DeleteConfirmDialogComponent, DeleteConfirmData, boolean>(
          DeleteConfirmDialogComponent,
          {
            data: {
              title: `Audio teamlid: ${getAudioMemberDisplayText(
                audioMember,
                false
              )}`,
              objectName: "audio teamlid",
              deleteInfo,
            },
          }
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            confirm();
          }
        });
    });
  }

  rowSelect(audioMember: AudioMember) {
    const index = this.selectedAudioMembers.indexOf(audioMember.id);

    if (this.selectedAudioMembers.length <= 1) {
      this.selectedAudioMembers.splice(0, this.selectedAudioMembers.length);
      if (index !== 0) {
        this.selectedAudioMembers.push(audioMember.id);
      }
      return;
    }

    if (index >= 0) {
      this.selectedAudioMembers.splice(index, 1);
    } else {
      this.selectedAudioMembers.push(audioMember.id);
    }
  }

  rowMultiSelect(audioMember: AudioMember, event: MouseEvent) {
    event.preventDefault();
    if (this.selectedAudioMembers.indexOf(audioMember.id) === -1) {
      this.selectedAudioMembers.push(audioMember.id);
    }
  }
}

function DataSourceSort(a: AudioMember, b: AudioMember, sort: Sort) {
  let value = 0;
  switch (sort.active) {
    case "name":
      value = `${a.lastName} ${a.firstName} ${a.middleName}`.localeCompare(
        `${b.lastName} ${b.firstName} ${b.middleName}`
      );
      break;
    case "phone":
      value = a.phone.localeCompare(b.phone);
      break;
    case "email":
      value = a.email.localeCompare(b.email);
      break;
    case "remark":
      value = a.remark.localeCompare(b.remark);
      break;
    default:
      value = a.id - b.id;
  }
  return value * (sort.direction === "desc" ? -1 : 1);
}
