import {
  CarService,
  CarServiceJson,
  carServiceJsonToCarService,
} from "src/app/shared/car-service-service/car-service";
import {
  Clerk,
  ClerkJson,
  clerkJsonToClerk,
} from "src/app/shared/clerk-service/clerk";
import {
  Cleaner,
  CleanerJson,
  cleanerJsonToCleaner,
} from "src/app/shared/cleaner-service/cleaner";
import {
  Babysitter,
  BabysitterJson,
  babysitterJsonToBabysitter,
} from "./babysitter-service/babysitter";
import {
  DoorService,
  DoorServiceJson,
  doorServiceJsonToDoorService,
} from "src/app/shared/door-service-service/door-service";
import {
  CoffeeService,
  CoffeeServiceJson,
  coffeeServiceJsonToCoffeeService,
} from "src/app/shared/coffee-service-service/coffee-service";
import {
  Elder,
  ElderJson,
  elderJsonToElder,
} from "src/app/shared/elder-service/elder";
import {
  Organist,
  OrganistJson,
  organistJsonToOrganist,
} from "src/app/shared/organist-service/organist";
import {
  Preacher,
  PreacherJson,
  preacherJsonToPreacher,
} from "src/app/shared/preacher-service/preacher";
import {
  Publication,
  PublicationJson,
  publicationJsonToPublication,
} from "src/app/shared/publication-service/publication";
import {
  Service,
  ServiceJson,
  serviceJsonToService,
} from "src/app/shared/service-service/service";

export interface DataReferencesJson {
  carServices?: CarServiceJson[];
  clerks?: ClerkJson[];
  cleaners?: CleanerJson[];
  babysitters?: BabysitterJson[];
  doorServices?: DoorServiceJson[];
  coffeeServices?: CoffeeServiceJson[];
  elders?: ElderJson[];
  organists?: OrganistJson[];
  preachers?: PreacherJson[];
  publications?: PublicationJson[];
  services?: ServiceJson[];
}

export interface DataReferences {
  carServices?: CarService[];
  clerks?: Clerk[];
  cleaners?: Cleaner[];
  babysitters?: Babysitter[];
  doorServices?: DoorService[];
  coffeeServices?: CoffeeService[];
  elders?: Elder[];
  organists?: Organist[];
  preachers?: Preacher[];
  publications?: Publication[];
  services?: Service[];
}

export function dataReferencesJsonToDataReferences(
  json: DataReferencesJson
): DataReferences {
  return {
    carServices: json.carServices
      ? json.carServices.map(carServiceJsonToCarService)
      : undefined,
    clerks: json.clerks ? json.clerks.map(clerkJsonToClerk) : undefined,
    cleaners: json.cleaners
      ? json.cleaners.map(cleanerJsonToCleaner)
      : undefined,
    babysitters: json.babysitters
      ? json.babysitters.map(babysitterJsonToBabysitter)
      : undefined,
    doorServices: json.doorServices
      ? json.doorServices.map(doorServiceJsonToDoorService)
      : undefined,
    coffeeServices: json.coffeeServices
      ? json.coffeeServices.map(coffeeServiceJsonToCoffeeService)
      : undefined,
    elders: json.elders ? json.elders.map(elderJsonToElder) : undefined,
    organists: json.organists
      ? json.organists.map(organistJsonToOrganist)
      : undefined,
    preachers: json.preachers
      ? json.preachers.map(preacherJsonToPreacher)
      : undefined,
    publications: json.publications
      ? json.publications.map(publicationJsonToPublication)
      : undefined,
    services: json.services
      ? json.services.map(serviceJsonToService)
      : undefined,
  };
}
