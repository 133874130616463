import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "boezemkerk-delete-confirm-dialog",
  templateUrl: "./new-service-dialog.component.html",
  styleUrls: ["./new-service-dialog.component.scss"]
})
export class NewServiceDialogComponent {
  readonly form = this.fb.group({
    date: [new Date()],
    time: ["9:30"],
  });

  constructor(
    private readonly dialogRef: MatDialogRef<NewServiceDialogComponent>,
    private readonly fb: FormBuilder
  ) {}

  onYesClick() {
    this.dialogRef.close(this.form.value);
  }

  onNoClick() {
    this.dialogRef.close(undefined);
  }
}
