import { PipeTransform, Pipe } from "@angular/core";
import {
  Babysitter,
  getBabysitterDisplayText
} from "src/app/shared/babysitter-service/babysitter";
import { BabysitterService } from "src/app/shared/babysitter-service/babysitter.service";
import { map } from "rxjs/operators";

@Pipe({ name: "babysitterDisplayText", pure: true })
export class BabysitterDisplayTextPipe implements PipeTransform {
  transform(babysitter: Babysitter, lastNameFirst = true) {
    return getBabysitterDisplayText(babysitter, lastNameFirst);
  }
}

@Pipe({ name: "babysitterDisplayTextAsync", pure: true })
export class BabysitterDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly babysitterService: BabysitterService) {}

  transform(babysitterId: number, lastNameFirst = true) {
    return this.babysitterService.data$.pipe(
      map(babysitters => {
        const babysitter = babysitters.find(value => value.id === babysitterId);
        return babysitter ? getBabysitterDisplayText(babysitter, lastNameFirst) : "";
      })
    );
  }
}
