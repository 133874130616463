import { PipeTransform, Pipe } from "@angular/core";
import { Clerk, getClerkDisplayText } from "src/app/shared/clerk-service/clerk";
import { ClerkService } from "src/app/shared/clerk-service/clerk.service";
import { map } from "rxjs/operators";

@Pipe({ name: "clerkDisplayText", pure: true })
export class ClerkDisplayTextPipe implements PipeTransform {
  transform(clerk: Clerk, lastNameFirst = true) {
    return getClerkDisplayText(clerk, lastNameFirst);
  }
}

@Pipe({ name: "clerkDisplayTextAsync", pure: true })
export class ClerkDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly clerkService: ClerkService) {}

  transform(clerkId: number, lastNameFirst = true) {
    return this.clerkService.data$.pipe(
      map(clerks => {
        const clerk = clerks.find(value => value.id === clerkId);
        return clerk ? getClerkDisplayText(clerk, lastNameFirst) : "";
      })
    );
  }
}
