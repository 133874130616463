import { Component, Input } from "@angular/core";
import { ElderService } from "src/app/shared/elder-service/elder.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Elder } from "src/app/shared/elder-service/elder";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-elder-details",
  templateUrl: "./elder-details.component.html",
  styleUrls: ["./elder-details.component.scss"]
})
export class ElderDetailsComponent {
  form = this.createForm(undefined);

  readonly elder$ = combineLatest(
    this.elderService.data$,
    this.route.paramMap
  ).pipe(
    map(([elders, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return elders.find(elder => elder.id === id);
    }),
    tap(elder => {
      this.form = this.createForm(elder);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly elderService: ElderService,
    private readonly fb: FormBuilder
  ) {}

  createForm(elder?: Elder) {
    return this.fb.group({
      firstName: [elder ? elder.firstName : ""],
      middleName: [elder ? elder.middleName : ""],
      lastName: [elder ? elder.lastName : ""],
      remark: [elder ? elder.remark : ""]
    });
  }

  onSubmit(elder: Elder) {
    this.elderService.save({ ...elder, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
