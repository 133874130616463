import { Component, Input } from "@angular/core";
import { PreacherService } from "src/app/shared/preacher-service/preacher.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Preacher } from "src/app/shared/preacher-service/preacher";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-preacher-details",
  templateUrl: "./preacher-details.component.html",
  styleUrls: ["./preacher-details.component.scss"]
})
export class PreacherDetailsComponent {
  form = this.createForm(undefined);

  readonly preacher$ = combineLatest(
    this.preacherService.data$,
    this.route.paramMap
  ).pipe(
    map(([preachers, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return preachers.find(preacher => preacher.id === id);
    }),
    tap(preacher => {
      this.form = this.createForm(preacher);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly preacherService: PreacherService,
    private readonly fb: FormBuilder
  ) {}

  createForm(preacher?: Preacher) {
    return this.fb.group({
      title: [preacher ? preacher.title : ""],
      firstName: [preacher ? preacher.firstName : ""],
      middleName: [preacher ? preacher.middleName : ""],
      lastName: [preacher ? preacher.lastName : ""],
      location: [preacher ? preacher.location : ""],
      phone: [preacher ? preacher.phone : ""],
      email: [preacher ? preacher.email : ""],
      remark: [preacher ? preacher.remark : ""]
    });
  }

  onSubmit(preacher: Preacher) {
    this.preacherService.save({ ...preacher, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
