import { PipeTransform, Pipe } from "@angular/core";
import {
  Organist,
  getOrganistDisplayText
} from "src/app/shared/organist-service/organist";
import { OrganistService } from "src/app/shared/organist-service/organist.service";
import { map } from "rxjs/operators";

@Pipe({ name: "organistDisplayText", pure: true })
export class OrganistDisplayTextPipe implements PipeTransform {
  transform(organist: Organist, lastNameFirst = true) {
    return getOrganistDisplayText(organist, lastNameFirst);
  }
}

@Pipe({ name: "organistDisplayTextAsync", pure: true })
export class OrganistDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly organistService: OrganistService) {}

  transform(organistId: number, lastNameFirst = true) {
    return this.organistService.data$.pipe(
      map(organists => {
        const organist = organists.find(value => value.id === organistId);
        return organist ? getOrganistDisplayText(organist, lastNameFirst) : "";
      })
    );
  }
}
