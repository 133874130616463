import { Data, JsonData } from "src/app/shared/data";
import { Collection, CollectionBox } from "src/app/shared/service-service/collection";

export interface ServiceJson extends JsonData {
  remark: string;
  dateTime: number;
  song: string;
  specialGuest: string;
  hasLiturgy: number;
  reading?: string;
  mainText?: string;
  theme?: string;
  announcements: string;
  preachersId?: number;
  eldersId?: number;
  elderDetail: string;
  organistsId?: number;
  carServicesId?: number;
  doorServices1Id?: number;
  doorServices2Id?: number;
  clerksId?: number;
  publicationsId?: number;
  cleaners1Id?: number;
  cleaners2Id?: number;
  coffeeServices1Id?: number;
  coffeeServices2Id?: number;
  audioMembersId?: number;
  babysitters1Id?: number;
  babysitters2Id?: number;
  babysitters3Id?: number;
  firstCollection: Collection.None;
  secondCollection: Collection.None;
  collectionBox: CollectionBox.None;
  extraCollection: Collection.None;
  collectionGoal: string;
}

export interface Service extends Data {
  remark: string;
  date: Date;
  time: string;
  song: string;
  specialGuest: string;
  hasLiturgy: boolean;
  reading?: string;
  mainText?: string;
  theme?: string;
  announcements: string;
  preachersId?: number;
  eldersId?: number;
  elderDetail: string;
  organistsId?: number;
  carServicesId?: number;
  doorServices1Id?: number;
  doorServices2Id?: number;
  clerksId?: number;
  publicationsId?: number;
  cleaners1Id?: number;
  cleaners2Id?: number;
  coffeeServices1Id?: number;
  coffeeServices2Id?: number;
  audioMembersId?: number;
  babysitters1Id?: number;
  babysitters2Id?: number;
  babysitters3Id?: number;
  firstCollection: Collection.None;
  secondCollection: Collection.None;
  collectionBox: CollectionBox.None;
  extraCollection: Collection.None;
  collectionGoal: string;
}

export function serviceJsonToService(json: ServiceJson): Service {
  let jsonDate = new Date(json.dateTime);
  return {
    ...json,
    date: new Date(
      jsonDate.getFullYear(),
      jsonDate.getMonth(),
      jsonDate.getDate()
    ),
    time: `${("00" + jsonDate.getHours()).slice(-2)}:${(
      "00" + jsonDate.getMinutes()
    ).slice(-2)}`,
    hasLiturgy: json.hasLiturgy === 1,
    archived: json.archived === 1,
  };
}
