import { PipeTransform, Pipe } from "@angular/core";

export function formatDate(date: Date) {
  return `${("00" + date.getDate()).slice(-2)}-${(
    "00" +
    (date.getMonth() + 1)
  ).slice(-2)}-${date.getFullYear()}`;
}

@Pipe({ name: "formatDate", pure: true })
export class FormatDatePipe implements PipeTransform {
  transform(date: Date) {
    return formatDate(date);
  }
}

export function formatTime(date: Date) {
  return `${("00" + date.getHours()).slice(-2)}:${(
    "00" + date.getMinutes()
  ).slice(-2)}`;
}

export function formatDateTime(date: Date) {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function isEqualDay(date1: Date, date2: Date) {
  if (!date1 || !date2) {
    return false;
  }
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

@Pipe({ name: "isEqualDate", pure: true })
export class IsEqualDatePipe implements PipeTransform {
  transform(date1: Date, date2: Date) {
    return isEqualDay(date1, date2);
  }
}

export function tableWidthCalculator(widths: { [key: string]: number }) {
  return (availableColumns: string[]) => {
    let sidePadding = 24;
    let defaultPadding = 10;
    let defaultCellPadding = defaultPadding * 2;
    let tableWidth =
      (sidePadding - defaultPadding) * 2 +
      availableColumns.length * defaultCellPadding;
    for (const column of availableColumns) {
      tableWidth += widths[column] || 0;
    }
    return `${tableWidth}px`;
  };
}
