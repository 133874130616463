import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { switchMap, map, startWith, shareReplay } from "rxjs/operators";
import { Sort } from "@angular/material/sort";

export abstract class BaseSource<TData, TSort, TRef>
  implements DataSource<TData>
{
  private readonly sort$ = new BehaviorSubject<Observable<Sort> | undefined>(
    undefined
  );

  public readonly dataAndRef$: Observable<{ data: TData[]; ref: TRef }>;
  public readonly data$: Observable<TData[]>;

  constructor(
    private readonly observable$: Observable<TData[]>,
    protected readonly sort: TSort,
    private readonly defaultSort: Sort,
    private readonly references$: Observable<TRef>
  ) {
    this.dataAndRef$ = this.sort$.pipe(
      switchMap((sort) => {
        const dataAndRef$ = combineLatest([this.observable$, this.references$]);
        if (sort) {
          return combineLatest([
            dataAndRef$,
            sort.pipe(
              startWith(this.defaultSort || { active: "", direction: "" as "" })
            ),
          ]).pipe(
            map(([[data, ref], sort]) => ({
              data: data.sort((a, b) => {
                return this.callSort(a, b, sort, ref);
              }),
              ref,
            }))
          );
        }
        return this.dataAndRef$;
      }),
      shareReplay(1)
    );
    this.data$ = this.dataAndRef$.pipe(map((dataAndRef) => dataAndRef.data));
  }

  connect() {
    return this.data$;
  }

  disconnect() {}

  protected abstract callSort(
    a: TData,
    b: TData,
    sort: Sort,
    references: TRef
  ): number;

  setSort(sort: Observable<Sort> | undefined) {
    this.sort$.next(sort);
  }
}
