import { Component } from "@angular/core";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Service } from "src/app/shared/service-service/service";
import { Collection, CollectionBox } from "src/app/shared/service-service/collection";
import { PreacherService } from "src/app/shared/preacher-service/preacher.service";
import { ElderService } from "src/app/shared/elder-service/elder.service";
import { OrganistService } from "src/app/shared/organist-service/organist.service";
import { CarServiceService } from "src/app/shared/car-service-service/car-service.service";
import { DoorServiceService } from "src/app/shared/door-service-service/door-service.service";
import { CoffeeServiceService } from "src/app/shared/coffee-service-service/coffee-service.service";
import { ClerkService } from "src/app/shared/clerk-service/clerk.service";
import { PublicationService } from "src/app/shared/publication-service/publication.service";
import { CleanerService } from "src/app/shared/cleaner-service/cleaner.service";
import { Preacher } from "src/app/shared/preacher-service/preacher";
import { Elder } from "src/app/shared/elder-service/elder";
import { Organist } from "src/app/shared/organist-service/organist";
import { CarService } from "src/app/shared/car-service-service/car-service";
import { DoorService } from "src/app/shared/door-service-service/door-service";
import { CoffeeService } from "src/app/shared/coffee-service-service/coffee-service";
import { Clerk } from "src/app/shared/clerk-service/clerk";
import { Publication } from "src/app/shared/publication-service/publication";
import { Cleaner } from "src/app/shared/cleaner-service/cleaner";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { AudioMember } from "src/app/shared/audio-member-service/audio-member";
import { AudioMemberService } from "src/app/shared/audio-member-service/audio-member.service";
import { Babysitter } from "src/app/shared/babysitter-service/babysitter";
import { BabysitterService } from "src/app/shared/babysitter-service/babysitter.service";

@Component({
  selector: "boezemkerk-service-details",
  templateUrl: "./service-details.component.html",
  styleUrls: ["./service-details.component.scss"],
})
export class ServiceDetailsComponent {
  readonly allCollections = Collection.GetAll();
  readonly allCollectionBoxes = CollectionBox.GetAll();

  form = this.createForm(undefined);

  readonly service$ = combineLatest(
    this.serviceService.data$,
    this.route.paramMap
  ).pipe(
    map(([services, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return services.find((service) => service.id === id);
    }),
    tap((service) => {
      this.form = this.createForm(service);
    })
  );

  readonly preachers$: Observable<Preacher[]>;
  readonly elders$: Observable<Elder[]>;
  readonly organists$: Observable<Organist[]>;
  readonly carServices$: Observable<CarService[]>;
  readonly doorServices$: Observable<DoorService[]>;
  readonly coffeeServices$: Observable<CoffeeService[]>;
  readonly audioMembers$: Observable<AudioMember[]>;
  readonly clerks$: Observable<Clerk[]>;
  readonly publications$: Observable<Publication[]>;
  readonly cleaners$: Observable<Cleaner[]>;
  readonly babysitters$: Observable<Babysitter[]>;

  readonly hasServiceGeneralRightLevel$: Observable<boolean>;
  readonly hasCarServiceRightLevel$: Observable<boolean>;
  readonly hasClerkRightLevel$: Observable<boolean>;
  readonly hasCleanerRightLevel$: Observable<boolean>;
  readonly hasBabysitterRightLevel$: Observable<boolean>;
  readonly hasCollectionRightLevel$: Observable<boolean>;
  readonly hasDoorServiceRightLevel$: Observable<boolean>;
  readonly hasCoffeeServiceRightLevel$: Observable<boolean>;
  readonly hasAudioMemberRightLevel$: Observable<boolean>;
  readonly hasElderRightLevel$: Observable<boolean>;
  readonly hasOrganistRightLevel$: Observable<boolean>;
  readonly hasPreacherRightLevel$: Observable<boolean>;
  readonly hasPublicationRightLevel$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly serviceService: ServiceService,
    authentication: AuthenticationService,
    preacherService: PreacherService,
    elderService: ElderService,
    organistService: OrganistService,
    carServiceService: CarServiceService,
    doorServiceService: DoorServiceService,
    coffeeServiceService: CoffeeServiceService,
    audioMemberService: AudioMemberService,
    clerkService: ClerkService,
    publicationService: PublicationService,
    cleanerService: CleanerService,
    babysitterService: BabysitterService,
    private readonly fb: FormBuilder
  ) {
    this.preachers$ = preacherService.data$.pipe(
      map((preachers) => [...preachers].sort(sortName))
    );
    this.elders$ = elderService.data$.pipe(
      map((elders) => [...elders].sort(sortName))
    );
    this.organists$ = organistService.data$.pipe(
      map((organists) => [...organists].sort(sortName))
    );
    this.carServices$ = carServiceService.data$.pipe(
      map((carServices) => [...carServices].sort(sortName))
    );
    this.doorServices$ = doorServiceService.data$.pipe(
      map((doorServices) => [...doorServices].sort(sortName))
    );
    this.coffeeServices$ = coffeeServiceService.data$.pipe(
      map((coffeeServices) => [...coffeeServices].sort(sortName))
    );
    this.audioMembers$ = audioMemberService.data$.pipe(
      map((audioMembers) => [...audioMembers].sort(sortName))
    );
    this.clerks$ = clerkService.data$.pipe(
      map((clerks) => [...clerks].sort(sortName))
    );
    this.publications$ = publicationService.data$.pipe(
      map((publications) =>
        [...publications].sort((a, b) => {
          let value = a.dueDate.getFullYear() - b.dueDate.getFullYear();
          if (value === 0) {
            value = a.number - b.number;
          }
          return value;
        })
      )
    );
    this.cleaners$ = cleanerService.data$.pipe(
      map((cleaners) => [...cleaners].sort(sortName))
    );
    this.babysitters$ = babysitterService.data$.pipe(
      map((babysitters) => [...babysitters].sort(sortName))
    );

    this.hasServiceGeneralRightLevel$ = authentication
      .getRightLevel(RightModule.ServiceGeneral)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasCarServiceRightLevel$ = authentication
      .getRightLevel(RightModule.CarService)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasClerkRightLevel$ = authentication
      .getRightLevel(RightModule.Clerk)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasCleanerRightLevel$ = authentication
      .getRightLevel(RightModule.Cleaner)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasBabysitterRightLevel$ = authentication
      .getRightLevel(RightModule.Babysitter)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasCollectionRightLevel$ = authentication
      .getRightLevel(RightModule.Collection)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasDoorServiceRightLevel$ = authentication
      .getRightLevel(RightModule.DoorService)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasCoffeeServiceRightLevel$ = authentication
      .getRightLevel(RightModule.CoffeeService)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasAudioMemberRightLevel$ = authentication
      .getRightLevel(RightModule.AudioMember)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasElderRightLevel$ = authentication
      .getRightLevel(RightModule.Elder)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasOrganistRightLevel$ = authentication
      .getRightLevel(RightModule.Organist)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasPreacherRightLevel$ = authentication
      .getRightLevel(RightModule.Preacher)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
    this.hasPublicationRightLevel$ = authentication
      .getRightLevel(RightModule.Publication)
      .pipe(map((rightLevel) => rightLevel > RightLevel.Read));
  }

  createForm(service?: Service) {
    return this.fb.group({
      date: [service ? service.date : new Date()],
      time: [service ? service.time : ""],
      song: [service ? service.song : ""],
      specialGuest: [service ? service.specialGuest : ""],
      hasLiturgy: [service ? service.hasLiturgy : false],
      reading: [service ? service.reading : undefined],
      mainText: [service ? service.mainText : undefined],
      theme: [service ? service.theme : ""],
      preachersId: [service ? service.preachersId || null : null],
      eldersId: [service ? service.eldersId || null : null],
      elderDetail: [service ? service.elderDetail : ""],
      organistsId: [service ? service.organistsId || null : null],
      carServicesId: [service ? service.carServicesId || null : null],
      doorServices1Id: [service ? service.doorServices1Id || null : null],
      doorServices2Id: [service ? service.doorServices2Id || null : null],
      coffeeServices1Id: [service ? service.coffeeServices1Id || null : null],
      coffeeServices2Id: [service ? service.coffeeServices2Id || null : null],
      audioMembersId: [service ? service.audioMembersId || null : null],
      clerksId: [service ? service.clerksId || null : null],
      publicationsId: [service ? service.publicationsId || null : null],
      cleaners1Id: [service ? service.cleaners1Id || null : null],
      cleaners2Id: [service ? service.cleaners2Id || null : null],
      babysitters1Id: [service ? service.babysitters1Id || null : null],
      babysitters2Id: [service ? service.babysitters2Id || null : null],
      babysitters3Id: [service ? service.babysitters3Id || null : null],
      firstCollection: [service ? service.firstCollection : Collection.None],
      secondCollection: [service ? service.secondCollection : Collection.None],
      collectionBox: [service ? service.collectionBox : CollectionBox.None],
      extraCollection: [service ? service.extraCollection : Collection.None],
      collectionGoal: [service ? service.collectionGoal : ""],
      remark: [service ? service.remark : ""],
    });
  }

  onSubmit(service: Service) {
    const checkValues: {
      reading: string | undefined,
      mainText: string | undefined,
      theme: string | undefined
    } = this.form.value;
    checkValues.reading = checkValues.reading ? checkValues.reading.trim() : undefined;
    checkValues.mainText = checkValues.mainText ? checkValues.mainText.trim() : undefined;
    checkValues.theme = checkValues.theme ? checkValues.theme.trim() : undefined;
    this.serviceService.save(
      {
        ...service,
        ...this.form.value,
        hasLiturgy: !!this.form.value.hasLiturgy,
        reading: checkValues.reading || undefined,
        mainText: checkValues.mainText || undefined,
        theme: checkValues.theme || undefined,
        preachersId: parseInt(this.form.value.preachersId) || undefined,
        eldersId: parseInt(this.form.value.eldersId) || undefined,
        organistsId: parseInt(this.form.value.organistsId) || undefined,
        carServicesId: parseInt(this.form.value.carServicesId) || undefined,
        doorServices1Id: parseInt(this.form.value.doorServices1Id) || undefined,
        doorServices2Id: parseInt(this.form.value.doorServices2Id) || undefined,
        coffeeServices1Id:
          parseInt(this.form.value.coffeeServices1Id) || undefined,
        coffeeServices2Id:
          parseInt(this.form.value.coffeeServices2Id) || undefined,
        audioMembersId: parseInt(this.form.value.audioMembersId) || undefined,
        clerksId: parseInt(this.form.value.clerksId) || undefined,
        publicationsId: parseInt(this.form.value.publicationsId) || undefined,
        cleaners1Id: parseInt(this.form.value.cleaners1Id) || undefined,
        cleaners2Id: parseInt(this.form.value.cleaners2Id) || undefined,
        babysitters1Id: parseInt(this.form.value.babysitters1Id) || undefined,
        babysitters2Id: parseInt(this.form.value.babysitters2Id) || undefined,
        babysitters3Id: parseInt(this.form.value.babysitters3Id) || undefined,
      },
      () => {
        this.router.navigate([".."], { relativeTo: this.route });
      }
    );
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}

function sortName(
  a: { firstName: string; middleName: string; lastName: string },
  b: { firstName: string; middleName: string; lastName: string }
) {
  return `${a.lastName} ${a.firstName} ${a.middleName}`.localeCompare(
    `${b.lastName} ${b.firstName} ${b.middleName}`
  );
}
