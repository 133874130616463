import { PipeTransform, Pipe } from "@angular/core";
import {
  AudioMember,
  getAudioMemberDisplayText
} from "src/app/shared/audio-member-service/audio-member";
import { AudioMemberService } from "src/app/shared/audio-member-service/audio-member.service";
import { map } from "rxjs/operators";

@Pipe({ name: "audioMemberDisplayText", pure: true })
export class AudioMemberDisplayTextPipe implements PipeTransform {
  transform(
    audioMember: AudioMember,
    lastNameFirst = true
  ) {
    return getAudioMemberDisplayText(audioMember, lastNameFirst);
  }
}

@Pipe({ name: "audioMemberDisplayTextAsync", pure: true })
export class AudioMemberDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly audioMemberService: AudioMemberService) {}

  transform(
    audioMemberId: number,
    lastNameFirst = true
  ) {
    return this.audioMemberService.data$.pipe(
      map(audioMembers => {
        const audioMember = audioMembers.find(value => value.id === audioMemberId);
        return audioMember
          ? getAudioMemberDisplayText(audioMember, lastNameFirst)
          : "";
      })
    );
  }
}
