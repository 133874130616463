import { Component, Input } from "@angular/core";
import { CoffeeServiceService } from "src/app/shared/coffee-service-service/coffee-service.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { CoffeeService } from "src/app/shared/coffee-service-service/coffee-service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-coffee-service-details",
  templateUrl: "./coffee-service-details.component.html",
  styleUrls: ["./coffee-service-details.component.scss"]
})
export class CoffeeServiceDetailsComponent {
  form = this.createForm(undefined);

  readonly coffeeService$ = combineLatest(
    this.coffeeServiceService.data$,
    this.route.paramMap
  ).pipe(
    map(([coffeeServices, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return coffeeServices.find(coffeeService => coffeeService.id === id);
    }),
    tap(coffeeService => {
      this.form = this.createForm(coffeeService);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly coffeeServiceService: CoffeeServiceService,
    private readonly fb: FormBuilder
  ) {}

  createForm(coffeeService?: CoffeeService) {
    return this.fb.group({
      firstName: [coffeeService ? coffeeService.firstName : ""],
      middleName: [coffeeService ? coffeeService.middleName : ""],
      lastName: [coffeeService ? coffeeService.lastName : ""],
      remark: [coffeeService ? coffeeService.remark : ""]
    });
  }

  onSubmit(coffeeService: CoffeeService) {
    this.coffeeServiceService.save(
      { ...coffeeService, ...this.form.value },
      () => {
        this.router.navigate([".."], { relativeTo: this.route });
      }
    );
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
