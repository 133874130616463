import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  Clerk,
  ClerkJson,
  clerkJsonToClerk,
  getClerkDisplayText
} from "src/app/shared/clerk-service/clerk";
import { DataService } from "src/app/shared/data-service";
import { DataReferences } from "src/app/shared/data-references";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { RightModule } from "src/app/shared/authentication-service/right-module";

@Injectable()
export class ClerkService extends DataService<ClerkJson, Clerk, undefined> {
  protected csvColumnValueGetter = [
    {column: "name", title: "Naam", getter: (data: Clerk) => getClerkDisplayText(data)},
    {column: "phone", title: "Telefoon", getter: (data: Clerk) => data.phone},
    {column: "email", title: "Email", getter: (data: Clerk) => data.email},
    {column: "remark", title: "Opmerkingen", getter: (data: Clerk) => data.remark}
  ];
  protected csvFilename = "Kosters";

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string,
    private readonly serviceService: ServiceService
  ) {
    super(
      http,
      authenticationService,
      `${backendUrl}clerks`,
      RightModule.Clerk
    );
  }

  jsonToData(json: ClerkJson) {
    return clerkJsonToClerk(json);
  }

  dataToJson(data: Clerk) {
    return {
      ...data,
      archived: data.archived ? 1 : 0
    };
  }

  protected handleReferences(references: DataReferences) {
    if (references.services) {
      this.serviceService.updateReferences(references.services);
    }
  }
}
