import { Data, JsonData } from "src/app/shared/data";

export interface ClerkJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface Clerk extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
}

export function clerkJsonToClerk(json: ClerkJson): Clerk {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getClerkDisplayText(clerk: Clerk, lastNameFirst = true) {
  const result = `${clerk.firstName} ${clerk.middleName}`;
  if (lastNameFirst) {
    return `${clerk.lastName}, ${result}`;
  }
  return `${result} ${clerk.lastName}`;
}
