import { NgModule, Injectable, Type } from "@angular/core";
import {
  Routes,
  RouterModule,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { HandleidingComponent } from "src/app/handleiding/handleiding.component";
import { DienstenComponent } from "src/app/diensten/diensten.component";
import { DomineesComponent } from "src/app/dominees/dominees.component";
import { OuderlingenComponent } from "src/app/ouderlingen/ouderlingen.component";
import { OrganistenComponent } from "src/app/organisten/organisten.component";
import { AutodienstComponent } from "src/app/autodienst/autodienst.component";
import { DeurdienstComponent } from "src/app/deurdienst/deurdienst.component";
import { KoffiedienstComponent } from "src/app/koffiedienst/koffiedienst.component";
import { KCComponent } from "src/app/kc/kc.component";
import { KostersComponent } from "src/app/kosters/kosters.component";
import { SchoonmakersComponent } from "src/app/schoonmakers/schoonmakers.component";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { LoginComponent } from "src/app/login/login.component";
import { CarServiceDetailsComponent } from "src/app/autodienst/car-service-details/car-service-details.component";
import { ClerkDetailsComponent } from "src/app/kosters/clerk-details/clerk-details.component";
import { CleanerDetailsComponent } from "src/app/schoonmakers/cleaner-details/cleaner-details.component";
import { PublicationDetailsComponent } from "src/app/kc/publication-details/publication-details.component";
import { DoorServiceDetailsComponent } from "src/app/deurdienst/door-service-details/door-service-details.component";
import { CoffeeServiceDetailsComponent } from "src/app/koffiedienst/coffee-service-details/coffee-service-details.component";
import { OrganistDetailsComponent } from "src/app/organisten/organist-details/organist-details.component";
import { ElderDetailsComponent } from "src/app/ouderlingen/elder-details/elder-details.component";
import { PreacherDetailsComponent } from "src/app/dominees/preacher-details/preacher-details.component";
import { ServiceDetailsComponent } from "src/app/diensten/service-details/service-details.component";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { take, map } from "rxjs/operators";
import { of } from "rxjs";
import { AudioMemberDetailsComponent } from "src/app/audio-teams/audio-member-details/audio-member-details.component";
import { AudioTeamsComponent } from "src/app/audio-teams/audio-teams.component";
import { BabysitterDetailsComponent } from "src/app/oppas/babysitter-details/babysitter-details.component";
import { OppasComponent } from "src/app/oppas/oppas.component";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    protected readonly authentication: AuthenticationService,
    protected readonly router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authentication.isLoggedIn) {
      return this.getRightLevel().pipe(
        map(rightLevel => {
          const hasRights = rightLevel > RightLevel.None;
          if (!hasRights) {
            this.router.navigate(["/handleiding"]);
          }
          return hasRights;
        })
      );
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }

  getRightLevel() {
    return of(RightLevel.Delete);
  }
}

@Injectable()
export class ServiceAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication.getRightLevel(RightModule.Service).pipe(take(1));
  }
}

@Injectable()
export class PreacherAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.Preacher)
      .pipe(take(1));
  }
}

@Injectable()
export class ElderAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication.getRightLevel(RightModule.Elder).pipe(take(1));
  }
}

@Injectable()
export class OrganistAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.Organist)
      .pipe(take(1));
  }
}

@Injectable()
export class CarServiceAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.CarService)
      .pipe(take(1));
  }
}

@Injectable()
export class DoorServiceAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.DoorService)
      .pipe(take(1));
  }
}

@Injectable()
export class CoffeeServiceAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.CoffeeService)
      .pipe(take(1));
  }
}

@Injectable()
export class AudioMemberAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.AudioMember)
      .pipe(take(1));
  }
}

@Injectable()
export class PublicationAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication
      .getRightLevel(RightModule.Publication)
      .pipe(take(1));
  }
}

@Injectable()
export class ClerkAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication.getRightLevel(RightModule.Clerk).pipe(take(1));
  }
}

@Injectable()
export class CleanerAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication.getRightLevel(RightModule.Cleaner).pipe(take(1));
  }
}

@Injectable()
export class BabysitterAuthGuard extends AuthGuard {
  getRightLevel() {
    return this.authentication.getRightLevel(RightModule.Babysitter).pipe(take(1));
  }
}

export const AuthGuards: ReadonlyArray<Type<AuthGuard>> = [
  AuthGuard,
  ServiceAuthGuard,
  PreacherAuthGuard,
  ElderAuthGuard,
  OrganistAuthGuard,
  CarServiceAuthGuard,
  DoorServiceAuthGuard,
  CoffeeServiceAuthGuard,
  AudioMemberAuthGuard,
  PublicationAuthGuard,
  ClerkAuthGuard,
  CleanerAuthGuard,
  BabysitterAuthGuard
];

const routes: Routes = [
  {
    path: "handleiding",
    canActivate: [AuthGuard],
    component: HandleidingComponent,
    data: {title: "Handleiding"}
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "diensten",
    canActivate: [ServiceAuthGuard],
    children: [
      {
        path: ":type/:id",
        component: ServiceDetailsComponent
      },
      {
        path: ":type",
        component: DienstenComponent
      },
      {
        path: "",
        redirectTo: "alles",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "dominees/:id",
    canActivate: [PreacherAuthGuard],
    component: PreacherDetailsComponent
  },
  {
    path: "dominees",
    canActivate: [PreacherAuthGuard],
    component: DomineesComponent
  },
  {
    path: "ouderlingen/:id",
    canActivate: [ElderAuthGuard],
    component: ElderDetailsComponent
  },
  {
    path: "ouderlingen",
    canActivate: [ElderAuthGuard],
    component: OuderlingenComponent
  },
  {
    path: "organisten/:id",
    canActivate: [OrganistAuthGuard],
    component: OrganistDetailsComponent
  },
  {
    path: "organisten",
    canActivate: [OrganistAuthGuard],
    component: OrganistenComponent
  },
  {
    path: "autodienst/:id",
    canActivate: [CarServiceAuthGuard],
    component: CarServiceDetailsComponent
  },
  {
    path: "autodienst",
    canActivate: [CarServiceAuthGuard],
    component: AutodienstComponent
  },
  {
    path: "deurdienst/:id",
    canActivate: [DoorServiceAuthGuard],
    component: DoorServiceDetailsComponent
  },
  {
    path: "deurdienst",
    canActivate: [DoorServiceAuthGuard],
    component: DeurdienstComponent
  },
  {
    path: "koffiedienst/:id",
    canActivate: [CoffeeServiceAuthGuard],
    component: CoffeeServiceDetailsComponent
  },
  {
    path: "koffiedienst",
    canActivate: [CoffeeServiceAuthGuard],
    component: KoffiedienstComponent
  },
  {
    path: "audioteam/:id",
    canActivate: [AudioMemberAuthGuard],
    component: AudioMemberDetailsComponent
  },
  {
    path: "audioteam",
    canActivate: [AudioMemberAuthGuard],
    component: AudioTeamsComponent
  },
  {
    path: "kc/:id",
    canActivate: [PublicationAuthGuard],
    component: PublicationDetailsComponent
  },
  {
    path: "kc",
    canActivate: [PublicationAuthGuard],
    component: KCComponent
  },
  {
    path: "kosters/:id",
    canActivate: [ClerkAuthGuard],
    component: ClerkDetailsComponent
  },
  {
    path: "kosters",
    canActivate: [ClerkAuthGuard],
    component: KostersComponent
  },
  {
    path: "schoonmakers/:id",
    canActivate: [CleanerAuthGuard],
    component: CleanerDetailsComponent
  },
  {
    path: "schoonmakers",
    canActivate: [CleanerAuthGuard],
    component: SchoonmakersComponent
  },
  {
    path: "oppas/:id",
    canActivate: [BabysitterAuthGuard],
    component: BabysitterDetailsComponent
  },
  {
    path: "oppas",
    canActivate: [BabysitterAuthGuard],
    component: OppasComponent
  },
  {
    path: "",
    redirectTo: "/diensten",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
