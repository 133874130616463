import { Component, Input } from "@angular/core";
import { CarServiceService } from "src/app/shared/car-service-service/car-service.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { CarService } from "src/app/shared/car-service-service/car-service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-car-service-details",
  templateUrl: "./car-service-details.component.html",
  styleUrls: ["./car-service-details.component.scss"]
})
export class CarServiceDetailsComponent {
  form = this.createForm(undefined);

  readonly carService$ = combineLatest(
    this.carServiceService.data$,
    this.route.paramMap
  ).pipe(
    map(([carServices, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return carServices.find(carService => carService.id === id);
    }),
    tap(carService => {
      this.form = this.createForm(carService);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly carServiceService: CarServiceService,
    private readonly fb: FormBuilder
  ) {}

  createForm(carService?: CarService) {
    return this.fb.group({
      firstName: [carService ? carService.firstName : ""],
      middleName: [carService ? carService.middleName : ""],
      lastName: [carService ? carService.lastName : ""],
      phone: [carService ? carService.phone : ""],
      remark: [carService ? carService.remark : ""]
    });
  }

  onSubmit(carService: CarService) {
    this.carServiceService.save({ ...carService, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
