import { PipeTransform, Pipe } from "@angular/core";
import {
  CoffeeService,
  getCoffeeServiceDisplayText
} from "src/app/shared/coffee-service-service/coffee-service";
import { CoffeeServiceService } from "src/app/shared/coffee-service-service/coffee-service.service";
import { map } from "rxjs/operators";

@Pipe({ name: "coffeeServiceDisplayText", pure: true })
export class CoffeeServiceDisplayTextPipe implements PipeTransform {
  transform(coffeeService: CoffeeService, lastNameFirst = true) {
    return getCoffeeServiceDisplayText(coffeeService, lastNameFirst);
  }
}

@Pipe({ name: "coffeeServiceDisplayTextAsync", pure: true })
export class CoffeeServiceDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly coffeeServiceService: CoffeeServiceService) {}

  transform(coffeeServiceId: number, lastNameFirst = true) {
    return this.coffeeServiceService.data$.pipe(
      map(coffeeServices => {
        const coffeeService = coffeeServices.find(
          value => value.id === coffeeServiceId
        );
        return coffeeService
          ? getCoffeeServiceDisplayText(coffeeService, lastNameFirst)
          : "";
      })
    );
  }
}
