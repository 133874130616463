import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { ServerResult } from "src/app/shared/server-result";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";

interface StartupData {
  title: string;
}

interface StartupServerResult extends ServerResult {
  data?: StartupData;
}

@Injectable()
export class StartupService {
  private _data?: StartupData;
  get title() {
    return this._data ? this._data.title : "";
  }

  private readonly controlUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly authentication: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string
  ) {
    this.controlUrl = `${backendUrl}`;
  }

  load() {
    return this.http
      .get<StartupServerResult>(
        this.controlUrl,
        this.authentication.getHttpOptions()
      )
      .pipe(
        tap((result) => {
          this._data = result.data;
        })
      )
      .toPromise();
  }
}
