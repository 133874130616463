import { Data, JsonData } from "src/app/shared/data";

export interface PreacherJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  location: string;
  phone: string;
  email: string;
}

export interface Preacher extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  location: string;
  phone: string;
  email: string;
}

export function preacherJsonToPreacher(json: PreacherJson): Preacher {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getPreacherDisplayText(
  preacher: Preacher,
  lastNameFirst = true,
  location: boolean = false
) {
  const result = `${preacher.title} ${preacher.firstName} ${preacher.middleName}`;
  let locationDisplayText = "";
  if (location) {
    locationDisplayText = `, ${preacher.location}`;
  }
  if (lastNameFirst) {
    return `${preacher.lastName}, ${result}${locationDisplayText}`;
  }
  return `${result} ${preacher.lastName}${locationDisplayText}`;
}
