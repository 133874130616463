import { Data, JsonData } from "src/app/shared/data";

export interface BabysitterJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Babysitter extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export function babysitterJsonToBabysitter(json: BabysitterJson): Babysitter {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getBabysitterDisplayText(babysitter: Babysitter, lastNameFirst = true) {
  const result = `${babysitter.firstName} ${babysitter.middleName}`;
  if (lastNameFirst) {
    return `${babysitter.lastName}, ${result}`;
  }
  return `${result} ${babysitter.lastName}`;
}
