import { Component, Input } from "@angular/core";
import { PublicationService } from "src/app/shared/publication-service/publication.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Publication } from "src/app/shared/publication-service/publication";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-publication-details",
  templateUrl: "./publication-details.component.html",
  styleUrls: ["./publication-details.component.scss"],
})
export class PublicationDetailsComponent {
  form = this.createForm(undefined);

  readonly publication$ = combineLatest(
    this.publicationService.data$,
    this.route.paramMap
  ).pipe(
    map(([publications, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return publications.find((publication) => publication.id === id);
    }),
    tap((publication) => {
      this.form = this.createForm(publication);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly publicationService: PublicationService,
    private readonly fb: FormBuilder
  ) {}

  createForm(publication?: Publication) {
    return this.fb.group({
      number: [publication ? publication.number : 0],
      period: [publication ? publication.period : 0],
      dueDate: [publication ? publication.dueDate : new Date()],
      publicationDate: [publication ? publication.publicationDate : null],
      correction: [publication ? publication.correction : ""],
      remark: [publication ? publication.remark : ""],
    });
  }

  onSubmit(publication: Publication) {
    console.log(
      this.form.value.publicationDate,
      typeof this.form.value.publicationDate,
      this.form.value.publicationDate || undefined
    );
    this.publicationService.save(
      {
        ...publication,
        ...this.form.value,
        number: parseInt(this.form.value.number),
        period: parseInt(this.form.value.period),
        publicationDate: this.form.value.publicationDate || undefined,
      },
      () => {
        this.router.navigate([".."], { relativeTo: this.route });
      }
    );
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
