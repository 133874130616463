export enum RightModule {
  Admin,
  CarService,
  Clerk,
  Collection,
  Copy,
  DoorService,
  Elder,
  Organist,
  Preacher,
  Publication,
  Service,
  Cleaner,
  CoffeeService,
  ServiceGeneral,
  AudioMember,
  Babysitter
}
