import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  Babysitter,
  BabysitterJson,
  babysitterJsonToBabysitter,
  getBabysitterDisplayText
} from "src/app/shared/babysitter-service/babysitter";
import { DataService } from "src/app/shared/data-service";
import { DataReferences } from "src/app/shared/data-references";
import { ServiceService } from "src/app/shared/service-service/service.service";
import { AuthenticationService } from "src/app/shared/authentication-service/authentication.service";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { RightModule } from "src/app/shared/authentication-service/right-module";

@Injectable()
export class BabysitterService extends DataService<BabysitterJson, Babysitter, undefined> {
  protected csvColumnValueGetter = [
    {column: "name", title: "Naam", getter: (data: Babysitter) => getBabysitterDisplayText(data)},
    {column: "remark", title: "Opmerkingen", getter: (data: Babysitter) => data.remark}
  ];
  protected csvFilename = "Oppas";

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    @Inject(BACKEND_URL) backendUrl: string,
    private readonly serviceService: ServiceService
  ) {
    super(
      http,
      authenticationService,
      `${backendUrl}babysitters`,
      RightModule.Babysitter
    );
  }

  jsonToData(json: BabysitterJson) {
    return babysitterJsonToBabysitter(json);
  }

  dataToJson(data: Babysitter) {
    return {
      ...data,
      archived: data.archived ? 1 : 0
    };
  }

  protected handleReferences(references: DataReferences) {
    if (references.services) {
      this.serviceService.updateReferences(references.services);
    }
  }
}
