import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { tap, map } from "rxjs/operators";
import { ServerResult } from "src/app/shared/server-result";
import { BACKEND_URL } from "src/app/shared/backend-url";
import { EnumDictionary } from "src/app/shared/util/enum-dictionary";
import { RightModule } from "src/app/shared/authentication-service/right-module";
import { RightLevel } from "src/app/shared/authentication-service/right-level";
import { BehaviorSubject } from "rxjs";
import { Organisation } from "src/app/shared/authentication-service/organisation";

export interface AuthenticationServerResult extends ServerResult {
  data?: {
    sessionId: string;
    rights: EnumDictionary<RightModule, RightLevel>;
    organisation: Organisation | undefined;
    csvSeparator: string;
  };
}

@Injectable()
export class AuthenticationService {
  private readonly _sessionId = new BehaviorSubject<string | undefined>(undefined);
  get isLoggedIn() {
    return this._sessionId.value && this._sessionId.value.length > 0;
  }
  get isLoggedIn$() {
    return this._sessionId.pipe(map(id => id && id.length > 0));
  }

  private readonly rights = new BehaviorSubject<EnumDictionary<RightModule, RightLevel>>(
    {}
  );

  private readonly organisation = new BehaviorSubject<Organisation | undefined>(undefined);

  private _csvSeparator = ";";
  get csvSeparator() {
    return this._csvSeparator;
  }

  private readonly controlUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(BACKEND_URL) backendUrl: string
  ) {
    this.controlUrl = `${backendUrl}authentication`;
  }

  getHttpOptions() {
    const headers: { [name: string]: string } = {
      "Content-Type": "application/json"
    };
    if (this._sessionId.value && this._sessionId.value.length > 0) {
      headers["x-sid"] = this._sessionId.value;
    }
    return {
      headers: new HttpHeaders(headers)
    };
  }

  login(authData: { userMail: string; password: string }) {
    return this.http
      .post<AuthenticationServerResult>(
        `${this.controlUrl}/login`,
        authData,
        this.getHttpOptions()
      )
      .pipe(
        tap(value => {
          if (value.data) {
            this._sessionId.next(value.data.sessionId);
            this.rights.next(value.data.rights);
            this.organisation.next(value.data.organisation);
            this._csvSeparator = value.data.csvSeparator;
          } else {
            this._sessionId.next(undefined);
            this.rights.next({});
            this.organisation.next(undefined);
            this._csvSeparator = ";";
          }
        })
      );
  }

  logout() {
    return this.http
      .post(`${this.controlUrl}/logout`, {}, this.getHttpOptions())
      .pipe(
        tap(() => {
          this._sessionId.next(undefined);
          this.rights.next({});
          this.organisation.next(undefined);
        })
      );
  }

  getRightLevel(module: RightModule) {
    return this.rights.pipe(map(rights => rights[module] || RightLevel.None));
  }

  hasOrganisationRights(organisation: Organisation | undefined) {
    return this.organisation.pipe(map(org => !organisation || org === organisation));
  }
}
