import { Data, JsonData } from "src/app/shared/data";

export interface CleanerJson extends JsonData {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Cleaner extends Data {
  remark: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export function cleanerJsonToCleaner(json: CleanerJson): Cleaner {
  return {
    ...json,
    archived: json.archived === 1
  };
}

export function getCleanerDisplayText(cleaner: Cleaner, lastNameFirst = true) {
  const result = `${cleaner.firstName} ${cleaner.middleName}`;
  if (lastNameFirst) {
    return `${cleaner.lastName}, ${result}`;
  }
  return `${result} ${cleaner.lastName}`;
}
