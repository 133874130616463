import { Component, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NativeDateAdapter } from "@angular/material/core";
import {
  MatDateFormats,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import { Subscription, of } from "rxjs";
import {
  filter,
  distinctUntilChanged,
  switchMap,
  delay,
  map,
} from "rxjs/operators";
import { FilterData, FilterService } from "src/app/shared/filter/filter.service";

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === "input") {
      let day: string = date.getDate().toString();
      day = +day < 10 ? "0" + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? "0" + month : month;
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return date.toDateString();
  }
}
const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "numeric" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" },
  },
};

@Component({
  selector: "boezemkerk-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class FilterComponent implements OnDestroy {
  readonly form: FormGroup;

  get isActive() {
    return this.form.controls.from.value !== null || this.form.controls.to.value !== null;
  }

  readonly subscription = new Subscription();

  constructor(private readonly fb: FormBuilder, filterService: FilterService) {
    const filterData = filterService.data;
    this.form = this.fb.group({
      from: [filterData.from],
      to: [filterData.to],
    });

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          filter(() => this.form.valid),
          map(
            (value: FilterData): FilterData => {
              const from = value.from ? new Date(value.from) : null;
              const to = value.to ? new Date(value.to) : null;
              if (from) {
                from.setHours(0, 0, 0, 0);
              }
              if (to) {
                to.setHours(0, 0, 0, 0);
              }
              return {
                from,
                to,
              };
            }
          ),
          distinctUntilChanged((x: FilterData, y: FilterData) => {
            const xFrom = x.from ? x.from.getTime() : undefined;
            const yFrom = y.from ? y.from.getTime() : undefined;
            const xTo = x.to ? x.to.getTime() : undefined;
            const yTo = y.to ? y.to.getTime() : undefined;
            return xFrom === yFrom && xTo === yTo
            ;
          }),
          switchMap((value) => of(value).pipe(delay(500)))
        )
        .subscribe((value) => {
          filterService.data =value;
        })
    );
  }

  clearFrom() {
    this.form.controls.from.setValue(null);
  }

  clearTo() {
    this.form.controls.to.setValue(null);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
