import { Component } from "@angular/core";
import { BabysitterService } from "src/app/shared/babysitter-service/babysitter.service";
import { combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Babysitter } from "src/app/shared/babysitter-service/babysitter";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-babysitter-details",
  templateUrl: "./babysitter-details.component.html",
  styleUrls: ["./babysitter-details.component.scss"]
})
export class BabysitterDetailsComponent {
  form = this.createForm(undefined);

  readonly babysitter$ = combineLatest(
    this.babysitterService.data$,
    this.route.paramMap
  ).pipe(
    map(([babysitters, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return babysitters.find(babysitter => babysitter.id === id);
    }),
    tap(babysitter => {
      this.form = this.createForm(babysitter);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly babysitterService: BabysitterService,
    private readonly fb: FormBuilder
  ) {}

  createForm(babysitter?: Babysitter) {
    return this.fb.group({
      firstName: [babysitter ? babysitter.firstName : ""],
      middleName: [babysitter ? babysitter.middleName : ""],
      lastName: [babysitter ? babysitter.lastName : ""],
      remark: [babysitter ? babysitter.remark : ""]
    });
  }

  onSubmit(babysitter: Babysitter) {
    this.babysitterService.save({ ...babysitter, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
