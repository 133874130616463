import { PipeTransform, Pipe } from "@angular/core";
import { Elder, getElderDisplayText } from "src/app/shared/elder-service/elder";
import { ElderService } from "src/app/shared/elder-service/elder.service";
import { map } from "rxjs/operators";

@Pipe({ name: "elderDisplayText", pure: true })
export class ElderDisplayTextPipe implements PipeTransform {
  transform(elder: Elder, lastNameFirst = true, detail = "") {
    return getElderDisplayText(elder, lastNameFirst, detail);
  }
}

@Pipe({ name: "elderDisplayTextAsync", pure: true })
export class ElderDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly elderService: ElderService) {}

  transform(elderId: number, lastNameFirst = true, detail = "") {
    return this.elderService.data$.pipe(
      map(elders => {
        const elder = elders.find(value => value.id === elderId);
        return elder ? getElderDisplayText(elder, lastNameFirst, detail) : "";
      })
    );
  }
}
