import { PipeTransform, Pipe } from "@angular/core";
import {
  Publication,
  getPublicationDisplayText
} from "src/app/shared/publication-service/publication";
import { PublicationService } from "src/app/shared/publication-service/publication.service";
import { map } from "rxjs/operators";

@Pipe({ name: "publicationDisplayText", pure: true })
export class PublicationDisplayTextPipe implements PipeTransform {
  transform(publication: Publication, year: boolean = false) {
    return getPublicationDisplayText(publication, year);
  }
}

@Pipe({ name: "publicationDisplayTextAsync", pure: true })
export class PublicationDisplayTextAsyncPipe implements PipeTransform {
  constructor(private readonly publicationService: PublicationService) {}

  transform(publicationId: number, year: boolean = false) {
    return this.publicationService.data$.pipe(
      map(publications => {
        const publication = publications.find(
          value => value.id === publicationId
        );
        return publication ? getPublicationDisplayText(publication, year) : "";
      })
    );
  }
}
