import { Pipe, PipeTransform } from "@angular/core";

export enum Collection {
  None,
  D,
  K,
  ZD,
  ZK,
  ED,
  EK,
  Z,
  HA,
}

export enum CollectionBox {
  None,
  W = 9,
  Z,
}

@Pipe({ name: "collectionText", pure: true })
export class CollectionTextPipe implements PipeTransform {
  transform(collection: Collection | CollectionBox) {
    return collectionText(collection);
  }
}

export function collectionText(collection: Collection | CollectionBox) {
  switch (collection) {
    case Collection.None:
    case CollectionBox.None:
      return "-";
    case Collection.D:
      return "D";
    case Collection.K:
      return "K";
    case Collection.ZD:
      return "ZD";
    case Collection.ZK:
      return "ZK";
    case Collection.ED:
      return "ED";
    case Collection.EK:
      return "EK";
    case Collection.Z:
      return "Z";
    case Collection.HA:
      return "HA";
    case CollectionBox.W:
      return "W";
    case CollectionBox.Z:
      return "Z";
    default:
      return "";
  }
}

export namespace Collection {
  export function GetAll() {
    return [
      Collection.None,
      Collection.D,
      Collection.K,
      Collection.Z,
      Collection.ZD,
      Collection.ZK,
      Collection.ED,
      Collection.EK,
      Collection.HA,
    ];
  }
}

export namespace CollectionBox {
  export function GetAll() {
    return [
      CollectionBox.None,
      CollectionBox.W,
      CollectionBox.Z,
    ];
  }
}
