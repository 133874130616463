import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeleteConfirmData } from "src/app/shared/delete-confirm-dialog/delete-confirm-data";

@Component({
  selector: "boezemkerk-delete-confirm-dialog",
  templateUrl: "./delete-confirm-dialog.component.html",
  styleUrls: ["./delete-confirm-dialog.component.scss"]
})
export class DeleteConfirmDialogComponent {
  get title() {
    return this.data.title;
  }

  get objectName() {
    return this.data.objectName;
  }

  get deleteInfo() {
    return this.data.deleteInfo;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: DeleteConfirmData
  ) {}

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
