import { Component } from "@angular/core";

@Component({
  selector: "boezemkerk-handleiding",
  templateUrl: "./handleiding.component.html",
  styleUrls: ["./handleiding.component.scss"]
})
export class HandleidingComponent {
  constructor() {}
}
