import { Observable, BehaviorSubject } from "rxjs";
import { Sort } from "@angular/material/sort";
import { BaseSource } from "src/app/shared/util/base-source";

export class Source<TData> extends BaseSource<
  TData,
  (a: TData, b: TData, sort: Sort) => number,
  void
> {
  constructor(
    observable$: Observable<TData[]>,
    sort: (a: TData, b: TData, sort: Sort) => number,
    defaultSort: Sort
  ) {
    super(observable$, sort, defaultSort, new BehaviorSubject<void>(undefined));
  }

  protected callSort(a: TData, b: TData, sort: Sort) {
    return this.sort(a, b, sort);
  }
}
