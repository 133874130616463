import { Component, Input } from "@angular/core";
import { ClerkService } from "src/app/shared/clerk-service/clerk.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { FormBuilder } from "@angular/forms";
import { Clerk } from "src/app/shared/clerk-service/clerk";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "boezemkerk-clerk-details",
  templateUrl: "./clerk-details.component.html",
  styleUrls: ["./clerk-details.component.scss"]
})
export class ClerkDetailsComponent {
  form = this.createForm(undefined);

  readonly clerk$ = combineLatest(
    this.clerkService.data$,
    this.route.paramMap
  ).pipe(
    map(([clerks, paramMap]) => {
      const id = Number.parseInt(paramMap.get("id") || "", 10) || 0;
      return clerks.find(clerk => clerk.id === id);
    }),
    tap(clerk => {
      this.form = this.createForm(clerk);
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly clerkService: ClerkService,
    private readonly fb: FormBuilder
  ) {}

  createForm(clerk?: Clerk) {
    return this.fb.group({
      firstName: [clerk ? clerk.firstName : ""],
      middleName: [clerk ? clerk.middleName : ""],
      lastName: [clerk ? clerk.lastName : ""],
      phone: [clerk ? clerk.phone : ""],
      email: [clerk ? clerk.email : ""],
      remark: [clerk ? clerk.remark : ""]
    });
  }

  onSubmit(clerk: Clerk) {
    this.clerkService.save({ ...clerk, ...this.form.value }, () => {
      this.router.navigate([".."], { relativeTo: this.route });
    });
  }

  onCancel() {
    this.router.navigate([".."], { relativeTo: this.route });
  }
}
